.design {
  .inside-design {
    width: 100%;

    .new-item {
      .input-box {
        input {
          background: transparent;
          color: white;
          margin-top: 14px;

          &::placeholder {
            color: white;
          }
        }
      }
    }

    .design-settings {
      padding: 10px 20px;
      display: flex;
      align-items: center;
      column-gap: 8px;
      background-color: rgb(255, 244, 244);

      svg {
        cursor: pointer;
      }

      .font-dropdown {
        border: none;
        outline: none;

        .dropdown-toggle,
        .dropdown-item {
          padding: 0px;
          color: black;
          font-family: "Poppins", monospace;
          font-size: 12px;
          font-weight: 400;
          line-height: normal;
          border: none;
          outline: none;

          &:active {
            background: transparent;
          }
        }

        .dropdown-menu {
          padding: 8px;
        }
      }

      .color-div {
        width: 25px;
        height: 25px;
        border: none;
        outline: none;
      }

      .lineheight-dropdown {
        color: black;

        .dropdown-toggle {
          padding: 0px;
          display: flex;
          align-items: center;
          column-gap: 10px;
          cursor: pointer;
          outline: none;
          border: none;
        }

        .dropdown-menu {
          .dropdown-item {
            display: flex;
            align-items: center;
            column-gap: 5px;

            &:active {
              background: transparent;
            }

            span {
              color: black;
              font-family: "Poppins", monospace;
              font-size: 12px;
              font-weight: 400;
              line-height: normal;
            }

            .form-range {
              color: black;
            }
          }
        }
      }

      p {
        color: black;
        font-family: "Poppins", monospace;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        margin: 0px;
      }

      span {
        color: rgb(226, 232, 240);
      }

      .font-size {
        display: flex;
        align-items: center;
        column-gap: 20px;
      }
    }
  }

  .bottom-design {
    width: 100%;
    margin-top: 20px;

    .design-template {
      padding-left: 0;
      padding-right: 0;
      height: 450px;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      .templates-content {
        grid-template-columns: repeat(3, 1fr) !important;
        margin-top: 0;
      }
    }
  }

  .noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
}

@media screen and (max-width: 1300px) {
  .design {
    .inside-design {
      .new-item {
        .page-attrib {
          display: grid;
          grid-template-columns: repeat(2, 1fr);

          label {
            width: 100% !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .design {
    .inside-design {
      .design-settings {
        width: 100%;
        padding: 10px;
        column-gap: 10px;

        .color-div {
          width: 20px;
          height: 20px;
        }

        .lineheight-dropdown {
          .dropdown-toggle {
            column-gap: 5px;
          }

          .dropdown-menu {
            .dropdown-item {
              column-gap: 5px;
            }
          }
        }

        .font-size {
          column-gap: 14px;
        }
      }

      .input-box {
        flex-direction: column !important;

        label {
          width: 100% !important;
        }
      }

      .page-attrib {
        display: flex !important;
        flex-direction: column !important;
      }
    }

    .bottom-design .design-template .templates-content {
      display: flex;
      flex-direction: column;
    }

    .bottom-design .design-template .templates-content .template-card {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 400px) {
  .design {
    .inside-design {
      .design-settings {
        column-gap: 7px;

        .color-div {
          width: 20px;
          height: 20px;
        }

        .lineheight-dropdown {
          .dropdown-toggle {
            column-gap: 5px;
          }

          .dropdown-menu {
            .dropdown-item {
              column-gap: 5px;
            }
          }
        }

        .font-size {
          column-gap: 10px;
        }
      }
    }
  }
}