@import url("https://fonts.googleapis.com/css2?family=Joti+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



h1 {
  font-size: 48px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: 0em;
}

h1.large {
  font-size: 64px;
  font-weight: 600;
  line-height: normal;
}

h2 {
  font-size: 40px;
  font-weight: 500;
  line-height: normal;
}

h3 {
  font-size: 35px;
  font-weight: 500;
  line-height: normal;
}

h4 {
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

// ---- paragraphs
p {
  font-family: "Poppins", monospace;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.p2 {
  font-weight: 300;
}

.p3 {
  font-size: 14px;
  font-weight: 500;
}

.p4 {
  font-size: 13px;
  font-weight: 400;
  color: #a6a4a4;
  margin-bottom: 0;
}

.p5 {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  // text-decoration-line: underline;
  background-color: #AC50C6;

}

.sideMenuBtn {

  border-radius: 5px;
  padding: 7px 10px;

  &:hover {
    background-color: #AC50C6;
  }
}

.p5-1 {
  color: black;
  font-size: 16px;
  font-weight: 500;
}

.p6 {
  color: #343333;
  font-size: 20px;
  font-weight: 400;
}

.p4-1 {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0;
  color: black;
}

.p4-2 {
  font-size: 15px;
  font-weight: 400;
  color: #a6a4a4;
  margin-bottom: 0;
}

.body-large {
  font-size: 18px;
  font-weight: 500;
}

.body-medium {
  font-size: 13px;
  font-weight: 400;
}

.body-small {
  font-size: 12px;
  font-weight: 300;
}

.body-small-1 {
  font-size: 12px;
  font-weight: 400;
  color: black;
}

label.large {
  font-size: 20px;
  font-weight: 400;
}

label {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.new-head {
  font-size: 20px;
  font-weight: 500;
}

.new-para {
  font-size: 16px;
  font-weight: 300;
  margin-top: 16px;
}

.new-para-2 {
  font-size: 14px;
  font-weight: 400;
  color: #343333;
}

.new-h3 {
  color: #180045;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.new-heading {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
}

.text-black1 {
  color: #414141;
  font-size: 15px;
  font-weight: 400;
}

.text-black2 {
  font-size: 15px;
  font-weight: 400;
  color: black;
}

.p7 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  color: #343333;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}