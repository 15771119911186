.template29 {
  h1 {
    font-size: var(--subHeadingFontSize);
    color: black;
    font-family: var(--t29Font);
    line-height: var(--t29LineHeight);

  }

  h4 {
    font-size: var(--paraFontSize);
    color: black;
    font-weight: bold;
    font-family: var(--t29Font);
    line-height: var(--t29LineHeight);

  }

  h5 {
    font-size: var(--subHeadingFontSizethree);
    color: black;
    font-weight: bold;
    font-family: var(--t29Font);
    line-height: var(--t29LineHeight);

  }

  p {
    font-size: var(--subHeadingFontSizethree);
    color: black;
    margin-bottom: 0;
    font-family: var(--t29Font);
    line-height: var(--t29LineHeight);

  }

  ul {
    list-style-type: disc !important;
    font-family: var(--t29Font);
    line-height: var(--t29LineHeight);

  }

  li {
    font-size: var(--subHeadingFontSizethree);
    color: black;
    font-family: var(--t29Font);
    line-height: var(--t29LineHeight);

  }
}

.template29 {
  .top {
    display: flex;
    padding: 20px 20px 0px 20px;
    background: var(--t29Color);
    border: 1px solid #bbc9ff;
    border-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    .left {
      width: 40%;
      background: var(--t29Color);
      padding-top: 30px;
      padding-bottom: 20px;
      display: flex;
      align-items: end;
      justify-content: center;
      flex-direction: column;
      // border: 2px solid #3a4486;
      // border-right: 0px;
      // border-bottom: 0px;
      padding-right: 30px;
      text-align: justify;
      // text-justify: inter-word;


      p {
        line-height: 30px;

      }
    }

    .right {
      padding-top: 30px;
      width: 60%;
      background: var(--t29Color);
      padding-bottom: 20px;
      display: flex;
      padding-left: 30px;
      flex-direction: column;
      // border: 2px solid #3a4486;
      // border-left: 0px;
      // border-bottom: 0px;
    }
  }

  .bottom {
    display: flex;
    padding: 0px 21px 20px 21px;

    .left {
      width: 40%;
      padding: 20px 20px 50px 20px;

      // border: 2px solid #3a4486;
      // border-right: 0px;
      // border-top: 0px;
      text-align: justify;

      .content {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;

        ul {
          padding-left: 20px;
          padding-top: 10px;
        }
      }

      .lang {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;

        ul {
          //   padding-left: 20px;
          padding-top: 10px;

          li {
            list-style: none;
          }
        }
      }

      .ref {
        padding-bottom: 20px;

        p {
          span {
            font-weight: bold;
          }
        }

        .referenceSingle {
          margin-bottom: 10px;
        }
      }
    }

    .right {
      width: 60%;
      padding: 20px 20px 50px 20px;

      // border: 2px solid #3a4486;
      // border-left: 0px;
      // border-top: 0px;
      .experience {
        .content {
          padding-top: 10px;
          padding-bottom: 10px;

          .job-position {
            margin-left: 15px;
            padding-left: 30px;
            margin-top: 5px;
            border-left: 2px solid black;
          }
        }
      }

      .education {
        .content {
          display: flex;
          padding-top: 10px;
          padding-bottom: 10px;

          //   padding-bottom: 20px;
          .left-content {
            width: 100%;

            .degree {
              font-weight: bold;
            }
          }

          // .right-content {
          //   width: 60%;
          // }
        }
      }

      .volunteer {
        .content {
          padding-top: 10px;
          padding-bottom: 10px;

          .job-position {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1650px) {
  .template29 {
    h1 {
      font-size: 35px;
    }

    h4 {
      font-size: 22px;
    }
  }
}

@media screen and (max-width: 1450px) {
  .template29 {
    h1 {
      font-size: 30px;
    }

    h4 {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 1250px) {
  .template29 {
    p {
      font-size: 13px;
    }

    h1 {
      font-size: 24px;
    }

    h4 {
      font-size: 17px;
    }

    .top {
      .left {
        padding-right: 0;
      }
    }

    .bottom {
      .right {
        .experience {
          .content {
            padding-top: 20px;
          }
        }
      }
    }
  }
}