.job-exp-reuse ul{
    padding-left: 0;
}


.job-exp-reuse ul > li .exp-h4-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.job-exp-reuse ul > li .exp-h4-wrap h4 , .job-exp-reuse ul > li .exp-h4-wrap h5{
    font-size: 16px ;
    font-weight: 700;
}

.job-exp-reuse ul > li p:nth-child(2){
    font-size: 20px !important;
    margin-top: 0 !important;
    font-style: italic;
    font-weight: 500;
}

