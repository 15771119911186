.template8-sec {
  color: black;
  font-family: var(--t8Font);
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 30px 50px 50px 50px;

  font-family: var(--t8Font) !important;

  p {
    font-family: var(--t8Font) !important;

  }

  ul {
    font-family: var(--t8Font) !important;
  }

  li {
    font-family: var(--t8Font) !important;
  }

  .heading {
    font-size: 35px;
    line-height: var(--t8LineHeight);
    margin-bottom: 10px;
    color: var(--t8Color);
  }

  .sub-heading {
    font-size: var(--subHeadingFontSize);
    line-height: var(--t8LineHeight);
    font-weight: bold;
    margin: 0;
    color: var(--t8Color);
  }

  .para {
    font-size: var(--paraFontSize);
    margin: 0px;
    line-height: var(--t8LineHeight);

    p {
      font-size: var(--paraFontSize);
      margin: 0px;
      line-height: var(--t8LineHeight);
    }
  }

  .top {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    row-gap: 10px;

    div {
      display: flex;
      align-items: center;
      column-gap: 5px;
    }

    .iconColor {
      color: var(--t8Color);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .bold-para {
    font-weight: bold;
  }

  .bline {
    width: 100%;
    height: 1px;
    background-color: rgb(105, 105, 105);
  }

  ul {
    padding-left: 20px;

    li {
      list-style: disc;
      font-size: var(--paraFontSize);
      margin: 0px;
      line-height: var(--t8LineHeight);
    }
  }

  .interest-sec {
    ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}