:root {
  --black: #000000;
  --white: #ffffff;
  --grey: #b5b5b5;
  --purple: #4d47c3;
  --purple-dark: #180045;
  --brown: #595959;
  --light-brown: #a6a4a4;
  --facebook: #467ef1;
  --linkedIn: #51c2ef;
  --twitter: #23c3ff;
  --mess: #2190e8;
  --whatsapp: #3bf882;
}
