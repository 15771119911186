.main-t1 {
    background-color: #E9E9E9;
}

.main-t1 .t1-wrap {
    overflow-y: hidden;
}

.main-t1 .t1-wrap h2 {
    font-size: 18px;
}

.main-t1 .t1-wrap h3 {
    font-size: 20px;
}

.main-t1 .t1-wrap p {
    font-size: 14px;
}

.main-container-t1 {
    background-color: white;
    padding: 0;
}

.left {
    position: relative;
}

.left .left-row-t1 {
    height: 100%;
}

.left .empty-div1 {
    position: absolute;
    margin-bottom: 44px;
    width: 98%;
    background: #59BA6C;
    height: 477px;
    clip-path: polygon(0 0%, 100% 0%, 100% 24%, 0% 50%);
    left: 0;
}

.potrait-img-t1 {
    clip-path: polygon(0 0, 86% 0, 86% 74%, 0% 100%);
    position: absolute;
    z-index: 1;
    left: 21px;
    height: 300px;
    width: 247px;
}


.potrait-img-t1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.left-details {
    background: #202C3A;
    color: white;
    margin-top: 10px;
    clip-path: polygon(0 6%, 100% 0, 100% 100%, 0 100%);
    margin-top: 240px;
    margin-left: 20px;
    position: relative;
    height: 100%;
}

.left-details .empty-div {
    margin-bottom: 44px;
    position: absolute;
    width: 100%;
    background: #59BA6C;
    height: 123px;
    clip-path: polygon(100% 0%, 52% 9%, 100% 70%);
}

.border-style {
    margin-top: 10px;
    border-bottom: 1px solid #59BA6C;
    margin-bottom: 20px;
}

.left-details .border-style {
    margin-top: 50px;
}

.border-style .under-line {
    margin-top: 1px;
    border-bottom: 2px solid #59BA6C;
    margin-bottom: 0;
    width: 50px;
}


.left-details .skills-div {
    padding-bottom: 40px;
}

.left-details .skills-div .skills-details {
    overflow: hidden;
    height: 229px;
}

.left-details .skills-div .border-style h2 {
    text-transform: uppercase;
}

.left-details .skills-div>ul {
    padding: 0;
}

.left-details .skills-div ul>li {
    display: flex;
    justify-content: space-between;
}

.left-details .skills-div ul>li p input {
    width: 75px;
}

.left-details .skills-div .projects-details {
    height: 100px;
    overflow: hidden;
}

.left-details .skills-div .strengths-details {
    overflow: hidden;
    height: 100px;
}

.left-details .skills-div .languages-details {
    overflow: hidden;
    height: 100px;
}




.t1-wrap .right {
    position: relative;
    color: black;
    height: fit-content;
    padding-bottom: 30px;
}

.t1-wrap .right .empty-div2 {
    position: absolute;
    width: 100%;
    background: #59BA6C;
    height: 127px;
    clip-path: polygon(100% 0%, 70% 0%, 100% 100%);
    right: 0;
}

.t1-wrap .right .empty-div3 {
    position: absolute;
    width: 100%;
    background: #59BA6C;
    height: 127px;
    clip-path: polygon(100% 0%, 74% 100%, 100% 100%);
    right: 0;
    bottom: 0;
    z-index: 0;
}

.t1-wrap .right .personal-details {
    margin-bottom: 25px;
}

.t1-wrap .right .personal-details .H1s {
    font-size: 34px;
}

.main-t1 .t1-wrap .right .personal-details h4 {
    font-size: 20px;
}


.t1-wrap .right .personal-details .H1s span {
    color: #59BA6C;
}

.t1-wrap .right .personal-details .your-contact {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
}

.t1-wrap .right .personal-details .your-contact li {
    display: flex;
}

.t1-wrap .right .personal-details .your-contact>li .icon-wrap {
    background: #59BA6C;
    height: fit-content;
    border-radius: 4px;
    padding: 2px;
    margin-right: 5px;
}

.t1-wrap .right .border-style h2 {
    color: #59BA6C;
}

.t1-wrap .right .qualification {
    margin-top: 40px;
}

.t1-wrap .right .qualification ul {
    list-style-type: none;
    padding-left: 0;
}

.t1-wrap .right .qualification .education {
    position: relative;
    padding-left: 30px;
    padding-bottom: 30px;
    border-left: 1px solid #59BA6C;
}

.t1-wrap .right .qualification ul>li:last-child {
    padding-bottom: 0;
    border-left: 2px;
}

.t1-wrap .right .qualification ul>li::before {
    content: "";
    position: absolute;
    left: -5px;
    top: 0;
    width: 8px;
    height: 8px;
    border: 2px solid #59BA6C;
    background: white;
    border-radius: 50%;
}

.t1-wrap .right .qualification .education>p {
    margin-bottom: 2px;
}

.t1-wrap .right .qualification .education p strong {
    margin-bottom: 2px;
    text-transform: uppercase;
    font-size: 14px;
}


.horizontal-slider {
    background: #afb3b0;
    color: #59BA6C;
    background-color: #59BA6C;
}


@media (min-width : 1400px) and (max-width : 1600px) {
    .potrait-img-t1 {
        clip-path: polygon(0 0, 86% 0, 86% 74%, 0% 100%);
        position: absolute;
        z-index: 1;
        left: 21px;
        height: 300px;
        width: 210px;
    }
}

@media (max-width : 1252px) {
    .potrait-img-t1 {
        clip-path: polygon(0 0, 86% 0, 86% 74%, 0% 100%);
        position: absolute;
        z-index: 1;
        left: 21px;
        height: 300px;
        width: 226px;
    }
}

@media (min-width : 1025px) and (max-width : 1165px) {
    .potrait-img-t1 {
        clip-path: polygon(0 0, 86% 0, 86% 74%, 0% 100%);
        position: absolute;
        z-index: 1;
        top: 20px;
        left: 21px;
        height: 270px;
        width: 180px;
    }

    .left-details {
        background: #202C3A;
        color: white;
        margin-top: 10px;
        clip-path: polygon(0 4%, 100% 0, 100% 100%, 0 100%);
        margin-top: 240px;
        margin-left: 20px;
        position: relative;
        height: 100%;
    }
}
@media (min-width : 770px) and (max-width : 840px) {
    .potrait-img-t1 {
        clip-path: polygon(0 0, 86% 0, 86% 74%, 0% 100%);
        position: absolute;
        z-index: 1;
        top: 20px;
        left: 21px;
        height: 270px;
        width: 200px;
    }

    .left-details {
        background: #202C3A;
        color: white;
        margin-top: 10px;
        clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 100%);
        margin-top: 240px;
        margin-left: 20px;
        position: relative;
        height: 100%;
    }
}