.landing-banner {
  padding-top: 52px;
  padding-bottom: 81px;

  h1 {
    span {
      background: #ac50c6;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  p {
    max-width: 690px;
    margin: 0 auto;
    margin-top: 10px;
  }

  .video {
    width: 1000px;
    // height: 600px;

  }

  .banner-btn {
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
    gap: 8px;
    flex-wrap: wrap;

    .br-10 {
      border-radius: 7px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .landing-banner .video {
    width: 1200px;
    // height: 680px;
  }
}

@media screen and (max-width: 1400px) {
  .landing-banner .video {
    width: 1000px;
    // height: 640px;
  }
}

@media screen and (max-width: 1200px) {
  .landing-banner .video {
    width: 800px;
    // height: 600px;
  }
}

@media screen and (max-width: 800px) {
  .landing-banner .video {
    width: 600px;
    // height: 500px;
  }
}

@media screen and (max-width: 600px) {
  .landing-banner {
    h1 {
      font-size: 20px;
    }

    p {
      font-size: 13px;
    }

    padding: 20px;

    .video {
      width: 300px;
      // height: 200px;
    }
  }
}

.modal-dialog {
  .modal-content {
    width: 100%;
    // height: 400px;
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn-close {
      // display: none;
      background-color: white;

      // filter: invert(1);


    }
  }
}

.modal-body {
  .create {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
      font-size: 14px;
      margin: 10px;
    }

    label {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .create-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    .new-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 160px;
      height: 40.693px;
      border-radius: 28.432px;
      background: #ac50c6;

      button {
        color: #fff;
        background: transparent;
        text-align: center;
        font-size: 10.267px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }
  }
}

.description {
  p {
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 0;
  }

  textarea {
    border: none;
    outline: none;
    border-radius: 8px;
    background: #fff;
    width: 671px;
    height: 156px;
    padding: 13px;
    margin-top: 4px;

    &::placeholder {
      font-size: 12px;
      color: #a6a4a4;
      font-weight: 300;
    }
  }

  .outer {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;

    button {
      width: 88px;
      height: 26px;
      border-radius: 36px;
      background: #ac50c6;
      color: #fff;
      font-size: 13px;
      font-weight: 300;
    }
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 34px;
  height: 34px;
  margin: 8px;
  border: 5px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ac50c6 transparent transparent transparent;
}


.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.createResumeOptions {
  background-color: transparent;
  height: 490px;
  width: 390px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
  padding: 0 40px;
  border: 2px dashed #1E2882;
  font-size: 16px;
  font-weight: 500;

}

.createResumeOption {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  padding: 15px 20px;
  width: 100%;
  border-radius: 5px;


}

.createResumeOption {
  // color: #AC50C6;

  &:hover {
    background-color: #AC50C6;
    // color: #ac50c6;

    img {
      filter: brightness(0) invert(1);
    }
  }


}


.lds-ringDashboard {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
}

.lds-ringDashboard div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 34px;
  height: 34px;
  margin: 8px;
  border: 5px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #180045 transparent transparent transparent;
}


.lds-ringDashboard div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ringDashboard div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ringDashboard div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ringDashboard {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// For Pricing Page

.lds-ringDashboardM {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
}

.lds-ringDashboardM div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 34px;
  height: 34px;
  margin: 8px;
  border: 5px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #AC50C6 transparent transparent transparent;
}


.lds-ringDashboardM div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ringDashboardM div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ringDashboardM div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ringDashboardM {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}