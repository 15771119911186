.template7-sec {
  color: black;
  font-family: var(--t7Font);
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 30px 50px 50px 50px;

  .skill {
    .content {
      .ist {
        ul {
          display: grid !important;
          grid-template-columns: repeat(3, 1fr) !important;
        }
      }
    }
  }

  .interests {
    ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .heading {
    font-size: 35px;
    line-height: var(--t7LineHeight);
    margin-bottom: 10px;
    color: var(--t7Color);
  }

  font-family: var(--t7Font) !important;

  p {
    font-family: var(--t7Font) !important;

  }

  ul {
    font-family: var(--t7Font) !important;
  }

  li {
    font-family: var(--t7Font) !important;
  }

  .sub-heading {
    font-size: var(--subHeadingFontSize);
    line-height: var(--t7LineHeight);
    font-weight: bold;
    margin: 0;
    color: var(--t7Color);
  }

  .para {
    font-size: var(--paraFontSize);
    margin: 0px;
    line-height: var(--t7LineHeight) !important;
  }

  .bold-para {
    font-weight: bold;
    font-family: var(--t7Font);
  }

  .top {
    display: flex;
    align-items: center;
    column-gap: 30px;

    div {
      display: flex;
      align-items: center;
      column-gap: 5px;
    }

    p {
      font-family: var(--t7Font);
    }
  }

  .borderline {
    width: 100%;
    height: 2px;
    background-color: rgb(105, 105, 105);
  }

  ul {
    padding-left: 20px;

    li {
      list-style: disc;
      font-size: 14px;
      margin: 0px;
      line-height: var(--t7LineHeight);
    }
  }

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .education,
  .work-experience {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .iconColor {
    color: var(--t7Color);
  }

  .para {
    p {
      font-size: var(--paraFontSize);
      margin: 0px;
      line-height: var(--t7LineHeight) !important;
    }
  }
}