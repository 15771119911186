.download {
  padding: 14px 0cqh 0 0px;

  .top {
    margin-top: 32px;

    img {
      margin-right: 6px;
    }
  }

  .one {
    img {
      margin-right: 16px;
    }
  }

  .inside-toolbox {
    width: 217px;
    margin-top: 31px;
    padding: 12px 0px 7px 12px;
    border-radius: 4px;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);

    .two {
      img {
        margin-right: 13px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .download {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}