.signin {
  background-color: var(--purple-dark);

  .signin-content {
    height: 100vh-(200px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    .right {

      .pass {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .eyebtn {
          // position: fixed;
          // margin-left: 15%;
          // margin-top: 12px;
          position: absolute;
          top: 30px;
          right: 15px;
        }
      }

      display: flex;
      flex-direction: column;

      .viewcnfpass {
        position: absolute;
        top: 330px;
        right: 30px;
      }

      .viewpass {
        position: absolute;
        top: 395px;
        right: 30px;
      }

      .login-btn {
        .changeBtn {
          width: 100%;
          height: 53px;
        }
      }

      .input-boxes {
        margin-top: 37px;

        input {
          width: 100%;
          border-radius: 8px;
          background: #f0efff;
          width: 369px;
          height: 54px;
          padding-left: 29px;
          padding-right: 29px;
          font-family: "Poppins";
          outline: none;
          border: none;
          position: relative;
        }

        // .viewpass1 {
        //   position: absolute;
        //   right: 30px;
        //   bottom: 295px;
        // }
        // .passicon{
        //   position: relative;
        //   // right: 25%;
        //   // top: 0%;
        //   .viewpass{
        //     position: absolute;
        //     right: 20%;
        //     top: 0%;
        //   }
        // }
        .password-input {
          padding-right: 40px;
          /* Adjust the padding to your preference */
        }



        // .passicon {
        //   position: absolute;
        //   right: 26%; /* Adjust the right position to your preference */
        //   top: 47%;
        //   transform: translateY(-50%);
        //   cursor: pointer;

        //   .viewpass,
        // .hidepass {
        //   position: absolute;
        //   right: 0%; /* Adjust the right position to your preference */
        //   top: 50%;
        //   left: 100%;
        //   transform: translateY(-50%);
        //   cursor: pointer;
        // }

        // }
        // .confirmPass {
        //   position: absolute;
        //   right: 26%; /* Adjust the right position to your preference */
        //   top: 55%;
        //   transform: translateY(-50%);
        //   cursor: pointer;

        //   .viewpass,
        // .hidepass {
        //   position: absolute;
        //   right: 0%; /* Adjust the right position to your preference */
        //   top: 50%;
        //   left: 100%;
        //   transform: translateY(-50%);
        //   cursor: pointer;
        // }

        // }
        .signuppass {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          .confirmPass {
            // position: fixed;
            // margin-left: 15%;
            // margin-top: 1%;
            position: absolute;
            top: 30px;
            right: 15px;
          }
        }

        .firstpass {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          .passicon {
            // position: fixed;
            // margin-left: 15%;
            // margin-top: 1%;
            position: absolute;
            right: 15px;
            top: 30px;
          }
        }

      }

      .icon-section {
        width: 100%;
        display: flex;
        gap: 30px;
      }
    }

    .left {
      min-height: 500px;
    }

    .cartoon-img {
      // position: absolute;
      margin-top: 300px;
    }
  }

  .shade {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);

    img {
      opacity: 50%;
    }
  }
}

@media screen and (max-width:1300px) {
  // .signin{
  //   .signin-content {
  //     .input-boxes {
  //       .passicon{
  //         right: 15% !important;
  //       }
  //       .confirmPass{
  //         right: 15% !important;
  //       }
  //     }
  //   }

  // } 
}

@media screen and (max-width:1200px) {
  // .signin{
  //   .signin-content {
  //     .input-boxes {
  //       .passicon{
  //         right: 11% !important;
  //       }
  //       .confirmPass{
  //         right: 11% !important;
  //       }
  //     }
  //   }

  // } 
}

@media (max-width: 1024px) {
  .signin .signin-content {
    height: 100vh- 200px;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 2;

    // .input-boxes {
    //   .passicon{
    //     right: 5% !important;
    //   }
    //   .confirmPass{
    //     right: 5% !important;
    //   }
    // }
  }

  .signin .signin-content .cartoon-img {
    margin-top: 300px;
    position: relative;
  }

  // sign in image

  .signin .signin-content .cartoon-img img svg {
    vertical-align: middle;
    max-width: 274px;
    position: absolute;
    top: -280px;
    left: -150px;
  }
}

@media (max-width: 900px) {
  .signin .signin-content .left {
    margin-top: 71px;
  }

  .signin .signin-content .cartoon-img {
    display: none;
  }
}

@media (max-width: 770px) {
  .signin .shade {
    display: none;
  }

  .signin .signin-content {
    flex-direction: column;
    justify-content: center;

    .input-boxes {
      margin-top: 37px;


      .firstpass {
        .passicon {
          // margin-left: 50% !important;
          // margin-top: 3% !important;
        }
      }

      .signuppass {
        .confirmPass {
          // margin-left: 50% !important;
          // margin-top: 3% !important;
        }
      }
    }

    .right {
      .pass {
        .eyebtn {
          margin-left: 50% !important;
        }
      }
    }
  }

  .signin .signin-content .left {
    margin-top: 70px;
    min-height: 100% !important;
  }
}

@media (max-width: 390px) {
  .signin {
    .signin-content {
      justify-content: center;
      //   .input-boxes{
      //   .passicon {
      //     position: absolute;
      //     right: 18% !important; /* Adjust the right position to your preference */
      //     top: 60% !important;
      //     transform: translateY(-50%);
      //     cursor: pointer;

      //     .viewpass,
      //   .hidepass {
      //     position: absolute;
      //     right: 0%; /* Adjust the right position to your preference */
      //     top: 50%;
      //     left: 100%;
      //     transform: translateY(-50%);
      //     cursor: pointer;
      //   }

      //   }
      //   .confirmPass {
      //     top: 67% !important;
      //     right: 18% !important; 
      //     .viewpass,
      //   .hidepass {
      //     position: absolute;
      //     right: 0%; /* Adjust the right position to your preference */
      //     top: 50%;
      //     left: 100%;
      //     transform: translateY(-50%);
      //     cursor: pointer;
      //   }

      //   }
      // }
      .left {
        h1 {
          font-size: 20px;

          .mob-hide {
            display: none !important;
          }
        }

        h3 {
          font-size: 20px;
          margin-top: 0 !important;

          .mob-hide {
            display: none !important;
          }
        }

        p {
          margin-top: 0 !important;
        }
      }

      .right {
        h2 {
          text-align: center;
        }

        .input-boxes {
          input {
            width: 310px;
            height: 54px;
          }
        }

        .login-btn {
          .changeBtn {
            width: 310px !important;
          }
        }

      }
    }
  }
}

.modal-dialog {
  .modal-content {
    border-radius: 8px;
    border: 2px solid rgba(255, 255, 255, 0.37);
    background: var(--glass,
        linear-gradient(180deg,
          rgba(255, 255, 255, 0.08) 0%,
          rgba(255, 255, 255, 0.08) 100%));
    backdrop-filter: blur(50px);

    .modal-header {
      p {
        font-size: 20px;
      }

      .modal-title {
        .btn-close {
          filter: brightness(0) invert(1);
          opacity: 1;
        }
      }
    }

    .modal-body {
      .new {
        p {
          font-size: 18px;
        }

        input {
          width: 100%;
          height: 40px;
          border-radius: 4px;
          border: none;
          outline: none;
          padding: 0px 20px;
        }
      }
    }

    .modal-footer {
      button {
        cursor: pointer;
        width: 150px;
        height: 35px;
        border-radius: 4px;
        border: none;
        outline: none;
        background: var(--glass,
            linear-gradient(180deg,
              rgba(255, 255, 255, 0.08) 0%,
              rgba(255, 255, 255, 0.08) 100%));

        &:last-child {
          background: #ac50c6;
        }
      }
    }
  }
}