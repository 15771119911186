.profileContainerMain {
    padding: 40px;

    h1 {
        font-size: 34px;
        padding: 0 10%;
    }

    .profileContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        // border: 1px solid black;
        flex-direction: row;

        padding: 2% 10%;
        gap: 50px;

        .left {
            img {
                border: 5px solid white;
                border-radius: 8px;
                overflow: hidden;
                width: 400px;
                height: 500px;
                object-fit: cover;
            }
        }

        .right {
            width: 70%;
            // border: 1px solid white;
            padding: 40px;
            display: flex;
            flex-direction: column;

            .inputForm {
                width: 100%;
            }

            .input-box {
                margin-bottom: 20px;
            }

            .profileSaveButton {
                align-self: flex-end;
                // width: 50px;
                padding: 5px 20px;
                border-radius: 10px;
                border: none;
                background-color: #AC50C6;

            }
        }
    }
}

@media screen and (max-width: 1400px) {

    .profileContainerMain {
        .profileContainer {
            flex-direction: column;
            align-items: center;
            gap: 10px;

            .left {
                img {
                    width: 300px;
                    height: 350px;
                }
            }

            .right {
                width: 100%;
            }
        }
    }

}

@media screen and (max-width: 600px) {
    .profileContainerMain {
        h1 {
            margin-bottom: 10px;
        }

        .profileContainer {
            padding: 0;

            .right {
                padding: 20px 0;

                .inputForm {
                    .input-box {
                        display: flex;
                        flex-direction: column;

                        label {
                            width: 100% !important;
                        }
                    }
                }
            }

            .left {
                img {
                    width: 250px;
                    height: 300px;
                }
            }

        }
    }
}