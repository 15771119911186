.bahr {
  .strength {
    // width: 763px;
    // height: 795px;
    border-radius: 8px;
    background: #fff;
    padding: 16px 27px 18px 34px;

    .editor-wrapper {
      position: relative;

      .writer-btn {
        position: absolute;
        bottom: 4%;
        left: 3%;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .bahr {
    .strength {
      padding: 16px 10px 18px 10px;
    }

    .time-period {
      .input-box {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);

        label {
          width: 100% !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .bahr {
    .input-box {
      display: flex !important;
      flex-direction: column;

      label {
        width: 100% !important;
      }
    }

    .time-period {
      .input-box {
        display: flex !important;
        flex-direction: column;

        label {
          width: 100% !important;
        }
      }
    }
  }
}

.bg-darkPurple {
  background-color: #180045;
}

.bg-darkPurpleDotted {
  background-color: transparent;
  border: 1px dashed #180045;
  color: black;
}