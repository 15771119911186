.joining {
  padding-top: 150px;
  padding-bottom: 150px;
  h1 {
    color: #1e2882;
  }
}
.footer {
  .lets-do {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px -2px 15px 0px rgba(0, 0, 0, 0.12);
    width: 1462px;
    height: 150px;
    flex-shrink: 0;
    padding-left: 70px;
    padding-right: 85px;
    margin: 0 auto;
    margin-top: -75px;
  }

  .footer-links {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 43px;
    border-bottom: 1px solid var(--white);
    .site-logo {
      margin-right: 200px;
    }
    .use-cases {
      ul {
        margin-top: 26px;
        display: flex;
        flex-direction: column;
        gap: 11px;
        .icons {
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          gap: 16px;
        }
        li {
          a {
            color: var(--white);
            font-family: "Poppins";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
    .links {
      display: flex;
      gap: 112px;
    }

    .subscribe {
      max-width: 320px;
      height: 30px;
      input {
        border-radius: 8px 0px 0px 8px;
        background: #e5e7eb;
        box-shadow: 0px 8px 24px -4px rgba(255, 255, 255, 0.08),
          0px 6px 12px -6px rgba(255, 255, 255, 0.12);
        width: 205.837px;
        height: 100%;
        color: #b1b5bb;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-left: 10px;
        outline: none;
        border: none;
      }
      button {
        border-radius: 0px 8px 8px 0px;
        margin-left: 5px;
      }
    }
  }
  .privacy {
    padding: 16px 285px;
    .privacy-links {
      display: flex;
      gap: 40px;
      a {
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: var(--white);
      }
    }
  }
}
@media screen and (max-width: 1700px) {
  .footer .lets-do {
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .footer .footer-links {
    .links {
      gap: 70px;
    }
    .site-logo {
      margin-right: 50px;
    }
  }
}
@media screen and (max-width: 992px) {
  .footer {
    .privacy {
      padding: 16px 30px;
      .privacy-links {
        gap: 14px;
      }
    }
    .footer-links {
      flex-direction: column;
      gap: 50px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      border-bottom: 1px solid var(--white);
      padding: 19px 17px;
      .links {
        gap: 30px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .joining {
    padding-top: 30px;
    h1.large {
      font-size: 30px;
    }
    p {
      font-size: 14px;
    }
  }
  .footer {
    .privacy {
      display: flex;
      .privacy-links {
        display: flex;
        flex-direction: column;
      }
      p {
        margin-top: 30px;
      }
    }
    .lets-do {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 2px;
      h4 {
        font-size: 16px;
      }
      .v-center {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
    .footer-links {
      justify-content: center;
      align-items: center;
      .site-logo {
        margin: 0 ;
      }
      .links {
        gap: 30px;
        display: flex;
        flex-direction: column;

     
      }
      .use-cases{
        label{
          align-items: center;
          justify-content: center;
          display: flex;
        }
        ul{
          align-items: center !important;

        }
      }
    }
  }
}
