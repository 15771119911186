.template11-sec {
  color: var(--t11Color);
  font-family: var(--t11Font);
  display: flex;
  flex-direction: column;
  padding: 30px 0px 50px 0px;
  background-color: white;

  font-family: var(--t11Font) !important;

  p {
    font-family: var(--t11Font) !important;

  }

  ul {
    font-family: var(--t11Font) !important;
  }

  li {
    font-family: var(--t11Font) !important;
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: #d8cabe;
  }

  .para {
    color: rgb(64, 64, 64);
    ;
    font-family: var(--t11Font);

    font-size: var(--paraFontSize);
    line-height: var(--t11LineHeight);
    margin: 0px;

    P {
      color: rgb(64, 64, 64);
      font-family: var(--t11Font);

      font-size: var(--paraFontSize);

      line-height: var(--t11LineHeight);

      margin: 0px;
    }
  }

  .top {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .user-img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      object-fit: cover;
    }

    .heading {

      font-family: var(--t11Font);


      line-height: var(--t11LineHeight);

      margin-bottom: 0px;
    }

    .status {
      font-size: var(--paraFontSize);

      font-family: var(--t11Font);

      line-height: var(--t11LineHeight);

      margin-bottom: 0px;
    }
  }

  .details {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 0px;
  }

  .sub-heading {
    font-size: 20px;
    line-height: var(--t11LineHeight);

    margin: 0;
    text-decoration: underline;
  }

  .about-sec {
    padding: 30px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 10px;

    p {
      text-align: center;
      width: 100%;
    }
  }

  .skills-sec,
  .strength-sec,
  .experience-sec,
  .project-sec,
  .volunteer-sec,
  .education-sec,
  .certificate-sec,
  .award-sec,
  .publication-sec,
  .interest-sec,
  .language-sec,
  .reference-sec {
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .ist {
      div {
        display: flex;
        align-items: center;
        column-gap: 10px;
      }
    }
  }

  .skills-sec,
  .strength-sec,
  .interest-sec,
  .language-sec {
    ol {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 5px 10px;

      li {
        p {
          padding-right: 5px;
        }
      }
    }
  }

  .education-sec,
  .certificate-sec,
  .award-sec {
    .ist {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
  }
}