* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // color: var(--white);
}

body {
  scroll-behavior: smooth;
  font-family: "Poppins", monospace;
  color: var(--white);
  font-style: normal;
  background-color: var(--purple-dark);
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
a,
.row,
.col {
  padding: 0;
  margin: 0;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}

.pointer {
  cursor: pointer;
}
button {
  border: none;
  padding: 0;
  color: white;
}

.img-100 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.v-center {
  display: flex;
  align-items: center;
}

.h-center {
  display: flex;
  justify-content: center;
}
.end {
  display: flex;
  justify-content: end;
}
.f-14 {
  font-size: 14px;
}

.br-35 {
  border-radius: 35px;
}
.br-8 {
  border-radius: 8px;
}
// text colors
.text-purple {
  color: var(--purple);
}
.text-black {
  color: var(--black);
}
.text-brown {
  color: var(--brown);
}
.text-light-brown {
  color: var(--light-brown);
}
.text-purple2 {
  color: var(--purple-dark);
}
.facebook {
  background-color: var(--facebook);
}
.linkedIn {
  background-color: var(--linkedIn);
}
.twitter {
  background-color: var(--twitter);
}
.mess {
  background-color: var(--mess);
}
.whatsapp {
  background-color: var(--whatsapp);
}
// images

.img-48 {
  width: 48px;
  height: 48px;
  object-fit: cover;
}
.img-53 {
  width: 53px;
  height: 53px;
  object-fit: cover;
}

.mt-14 {
  margin-top: 14px;
}

.w-85 {
  width: 85%;
}

.input-box {
  display: flex;
  gap: 14px;

  input {
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    height: 40px;
    width: 100%;
    padding: 0 20px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    outline: none;
    &::placeholder {
      color: #a6a4a4;
    }
  }
  textarea {
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    width: 100%;
    padding: 0 20px;
  }

  .one {
    background-color: red;
    width: 33%;
  }
}
.bg-scroll {
  background: #d9d9d9;
}

// Add custom field
.add-custom-field {
  margin-top: 40px;
  border-radius: 4%;
  border: 1px dashed #1e2882;
  button {
    color: #1e2882;
    background: #fff;
  }
}

.ql-toolbar.ql-snow {
  border-radius: 4px;
}
.ql-container.ql-snow {
  border: 1px solid #ccc;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0px;
  border-radius: 4px;
  color: black;
}

.ql-editor {
  min-height: 186px !important;
}
.mix-blend {
  mix-blend-mode: screen;
}
.pinky {
  color: #ac50c6;
}

// ------------------------------

/* Define the typewriter animation */
