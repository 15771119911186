.reviewed {
  padding: 100px 0 140px 0px;

  .heading {
    margin-bottom: 100px;
  }

  .slider {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;

    .slider-content-1 {
      display: flex !important;
      // width: 30%;
      width: 280px;
    }

    .slider-content-2 {
      .p2 {
        width: 100%;
      }
    }

    .client-name {
      margin-top: 20px;
    }

    .client-name span {
      margin-left: 12px;
    }

    .template-slider {
      max-width: 1040px;
      width: 100%;

      .slider-content-2 {
        margin-right: 40px;
      }
      .imgs{
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
      }
    }
  }

  .template-slider {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: -30px;
      height: 1px;
      width: 75%;
      background-color: white;
    }

    .slick-next {
      bottom: -30px;
      left: 70px;
      top: unset !important;

      &::before {
        font-family: "slick";
        font-size: 20px;
        line-height: 1;
        opacity: 1;
        color: white;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: url("../../assets/icons/rightSlider.svg");
      }
    }

    .slick-prev {
      bottom: -30px;
      left: 0px;
      top: unset !important;

      &::before {
        font-family: "slick";
        font-size: 20px;
        line-height: 1;
        color: white;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: url("../../assets/icons/leftSlider.svg");
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 1567px) {
  .reviewed {
    .iconify--ion {
      width: 30px;
      height: 30px;
    }

    .slider .template-slider {
      max-width: 880px;
      width: 100%;
    }

    .slider-content-1 {
      display: flex;
      justify-content: center;

      p {
        font-size: 14px;
        margin-bottom: 0 !important;
      }
    }

    .slider-content-2 {
      max-width: 240px;

      p {
        font-size: 14px;
        margin-bottom: 0 !important;
      }

      h3 {
        font-size: 26px;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .reviewed .slider .template-slider {
    max-width: 570px;
    width: 100%;
  }
  .reviewed .slider-content-2 h3 {
    font-size: 21px;
}
.reviewed .slider-content-2 p {
  font-size: 10px;
  margin-bottom: 0 !important;
}
}

@media screen and (max-width: 992px) {
  .reviewed {
    padding: 100px 50px;

    .heading {
      margin-bottom: 50px;

      h1 {
        font-size: 38px;
        line-height: 40px;
      }
    }

    .slider {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 50px;

      .template-slider {
        max-width: 100%;
      }

      .slider-content-1 {
        justify-content: flex-start;
      }
    }

    .slider-content-2 {
      h3 {
        font-size: 20px;
      }

      p {
        font-size: 13px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .reviewed {
    padding: 100px 23px;

    .heading {
      margin-bottom: 50px;

      h1 {
        font-size: 20px;
        line-height: 20px;
      }
    }

    .my-slider {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }

    .slider .slider-content-1 {
      justify-content: center;
      width: 100%;
    }

    .slick-slider {
      button {
        display: none !important;
      }
    }

    .slider {
      .template-slider {
        .slider-content-2 {
          margin-right: 0;
        }
      
      }
    }
    .template-slider{
      &::after{
        display: none !important;
      }
    }
  }
}