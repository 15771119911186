.contactContainer {
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: center;
    justify-content: center;
    // color: black;

    .mainHeading {
        font-size: 36px;
    }

    .subHeading {
        font-size: 16px;

    }

    .contactContent {
        display: flex;
        background-color: white;
        padding: 10px;
        border-radius: 10px;
        max-width: 1220px;
        height: 680px;

        .left {
            background-image: url("../../assets/images/contactus/contactbg.png");
            width: 40%;
            background-size: cover;
            background-position: right bottom;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 4% 4%;

            .contactInfo {
                h2 {
                    font-size: 28px;
                }

                p {
                    font-weight: 300;
                }
            }

            .contactDetails {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 35px;

                .contactDetail {
                    display: flex;
                    gap: 30px;
                    // align-items: flex-start;
                    // align-items: center;

                    p {
                        margin: 0;
                    }
                }
            }

            .contactSocials {
                display: flex;
                gap: 20px;

                .contactSocial {

                    background-color: white;
                    display: inline;
                    border-radius: 50%;
                    width: 32px;
                    height: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .right {
            width: 60%;
            padding: 4%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 20px;
            height: 100%;

            .input-box {
                label {
                    p {
                        margin-bottom: 5px;
                    }
                }
            }

            .profileSaveButton {
                align-self: flex-end;
                // width: 50px;
                padding: 5px 20px;
                border-radius: 10px;
                border: none;
                background-color: #AC50C6;

            }

        }
    }
}

.ql-editor.ql-blank {
    font-weight: 100;
}

.ql-editor.ql-blank:focus::before {
    content: '';
}

@media screen and (max-width: 1400px) {
    .contactContainer {
        padding: 0 5%;

        .contactContent {
            width: 100%;
            height: 720px;


            .left {

                // display: none;
                .contactDetails {
                    .contactDetail {
                        gap: 2px;
                    }
                }
            }

            .right {
                // width: 100%;
                gap: 10px;

                .input-box {
                    display: flex;
                    flex-direction: column;

                    label {
                        width: 100% !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .contactContainer {
        padding: 0 5%;

        .contactContent {
            width: 100%;
            // height: 100vh;
            flex-direction: column;
            height: max-content;


            .left {
                // display: none;
                width: 100%;


                .contactDetails {
                    // width: 70%;
                    margin-bottom: 150px;
                }
            }

            .right {
                width: 100%;
                // gap: 30px;


                .input-box {
                    display: flex;
                    flex-direction: column;

                    label {
                        width: 100% !important;
                    }
                }
            }
        }
    }
}

.contactTextArea {
    padding: 10px !important;
    // font-size: 10px;
}

.contactTextArea::placeholder {
    color: #bbbabe;
}