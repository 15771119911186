 .main-container-t4 > .row{
    border: 1.5rem solid #50A699;
    color: black;
 }
 .main-container-t4 .h2-wrap-reuse .icon-wrap{
    background: #50A699;
}


.main-container-t4 p{
    margin: 3px 0;
    font-size: 14px;
}

.main-container-t4 .main-left-t4{
    background: #D2E8E6;
}
.main-container-t4 .main-left-t4 .left-t4 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-container-t4 .main-left-t4 .left-t4 .person-det-t4 h1{
    font-size: 30px;
    font-weight: 800;
    text-align: center;
}
.main-container-t4 .main-left-t4 .left-t4 .person-det-t4 h3{
    font-size: 19px;
    font-weight: 400;
    text-align: center;
}

.main-left-t4 .left-t4 .t4-potrait .t4-potrait-img{
    width: 215px;
    height: 215px;
    border-radius: 50%;
    border: 12px solid white;
    overflow: hidden;
}

.main-left-t4 .left-t4 .t4-potrait .t4-potrait-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.main-left-t4 .left-t4 .contact-t4{
    border-bottom: 2px solid #474747;
    padding-bottom: 30px;
    margin-bottom: 30px;
}
.main-left-t4 .left-t4 .contact-t4 ul{
    list-style-type: none;
    padding: 10px 0;
}

.main-left-t4 .left-t4 .contact-t4 ul > li{
    display: flex;
    justify-content: start;

}
.main-left-t4 .left-t4 .contact-t4 ul > li .left-icon-wrap{
    margin-right: 8px;
}

.main-left-t4 .left-t4 .education-t4{
    border-bottom: 2px solid #474747;
    padding-bottom: 30px;
    margin-bottom: 35px;
}

.main-left-t4 .left-t4 .ref-wrap{
    list-style-type: none;
    padding: 0;
    text-align: center;
    margin-top: 15px;
}
.main-left-t4 .left-t4 .ref-wrap > li h4{
    margin: 6px 0;
    font-size: 16px;
    font-weight: bold;
}

.main-left-t4 .left-t4 .ref-wrap > li p{
    margin: 12px 0;
}
.main-left-t4 .left-t4 .strength{
    height: 200px;
    border-bottom: 2px solid #474747;
    padding-bottom: 30px;
    margin-bottom: 35px;
}
.main-left-t4 .left-t4 .projects{
    height: 200px;
    padding-bottom: 30px;
}



.main-right-t4 {
    position: relative;
    padding-left: 35px;
}

.main-right-t4::before{
    content: "";
    position: absolute;
    top: 0;
    left: 35px;
    width: 2px;
    height: 100%;
    background: #474747;
    border-radius: 15px;
    z-index: 1;
}

.main-right-t4 .right-t4 .about p{
    margin: 20px 0;
}

.main-right-t4 .job-exp .exp-wrap{
    padding: 0;
    list-style-type: none;
    border-bottom: 2px solid #474747;
    padding-bottom: 30px;
    margin-bottom: 30px;
}
.main-right-t4 .job-exp .exp-wrap .exp-h4-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1px;
}
.main-right-t4 .job-exp .exp-wrap .exp-h4-wrap h4{
    font-size: 14px;
    font-weight: bold;
}
.main-right-t4 .job-exp .exp-wrap .exp-h4-wrap h5{
    font-size: 14px;
}

.main-right-t4 .job-exp .exp-wrap .exp-det-wrap{
    margin: 10px 0;
}

.main-right-t4 .job-exp .exp-wrap .exp-det-wrap p:nth-child(2){
    font-style: italic;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 15px;
}


.main-right-t4 .skills-wrap{
    justify-content: space-between;
    border-bottom: 2px solid #474747;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.main-right-t4 .skills-wrap .skills-det-wrap{
    display: flex;
    flex-wrap: wrap;
}


.main-right-t4 .skills-wrap .skill-det{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-right-t4 .skills-wrap .skill-det p{
    flex: 1;
    font-weight: 500;
}

.main-right-t4 .skills-wrap .skill-det .progress{
    width: 100%;
    flex: 1;
    height: 10px;
    padding: 2px;
    border: 1px solid black;
    background-color: transparent;
}

.main-right-t4 .skills-wrap .skill-det .progress .progress-bar{
    background-color: #50A699;
}

.main-right-t4 .interest-t4{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.main-right-t4 .interest-t4 .language{
    flex: 1;
}

.main-right-t4 .interest-t4 .language ul{
    display: flex;
    flex-wrap: wrap;
}
.main-right-t4 .interest-t4 ul{
    margin: 15px 0;
}
.main-right-t4 .interest-t4 .language ul > li{
    width: 50%;
    font-size: 14px;
    font-weight: 500;
}
.main-right-t4 .interest-t4 ul > li{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    position: relative;
}
.main-right-t4 .interest-t4 .language ul > li::before{
    content: "";
    position: absolute;
    top: 6px;
    left: -11px;
    width: 8px;
    height: 8px;
    background: #50A699;
    border-radius: 15px;
    z-index: 1;
}
.main-right-t4 .interest-t4 ul > li::before{
    content: "";
    position: absolute;
    top: 6px;
    left: -11px;
    width: 8px;
    height: 8px;
    background: #50A699;
    border-radius: 15px;
    z-index: 1;
}

.main-right-t4 .interest-t4 .hobbies{
    flex: 1;
}