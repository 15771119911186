.h2-wrap-reuse{
    display: flex;
    align-items: center;
    position: relative;
}

.h2-wrap-reuse .icon-wrap{
    width: 36px;
    height: 36px;
    display: flex;
    border-radius: 50%;
    object-fit: cover;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

.h2-wrap-reuse h2{
    font-size: 19px;
    font-weight: bold;
}