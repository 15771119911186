.message {
  height: 100vh;
  .input-box {
    position: relative;
    z-index: 3;
    input {
      color: var(--white);
      width: 825px;
      height: 78px;
      padding-left: 43px;
      padding-right: 43px;
      outline: none;
      border-radius: 8px;
      border: none;
      background: var(
        --glass,
        linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.08) 0%,
          rgba(255, 255, 255, 0.08) 100%
        )
      );
      backdrop-filter: blur(50px);
    }
    .message-btn {
      position: absolute;
      z-index: 2;
      right: 34px;
      top: 13px;
      img {
        margin-right: 15px;
        mix-blend-mode: luminosity;
      }
    }
  }
  .shade {
    position: absolute;
    top: -90px;
    opacity: 70%;
    z-index: 2;
  }
}
