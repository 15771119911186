.main {
    background-color: #CBA707;
}

.main .main-container .main-row-t2{
    background-color: white;
    color: black;
    overflow-y: hidden;
}
.main .main-container .main-row-t2 h2{
    font-size: 20px;
}

.main .main-container .main-row-t2 p{
    font-size: 14px;
}

.main .main-container .main-row-t2 h5{
    font-size: 16px;
}


.left-main .potrait-img-wrap {
    display: flex;
    justify-content: center;
    position: relative;
}

.left-main .potrait-img-wrap .empty-div {
    width: 212px;
    height: 114px;
    background-color: white;
    position: absolute;
    z-index: 1;
    bottom: -346px;
    border-bottom-left-radius: 250px;
    border-bottom-right-radius: 250px;
    
}

.left-main .potrait-img-wrap .img-wrapper{
    position: inherit;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}


.left-main .potrait-img-wrap .potrait-img-t2 {
    position: absolute;
    width: 174px;
    height: 320px;
    background-color: #b5edc2;
    /* background-color: #CCA708; */
    border-bottom-left-radius: 250px;
    border-bottom-right-radius: 250px;
    overflow: hidden;
    z-index: 3;
    top: 0;

}

.left-main .potrait-img-wrap .potrait-img-t2 img {
    /* translate: -64px 0; */
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.left-main .left-row {
    background: #F6F6F6;
    /* translate: 0 248px; */
    padding-top: 60px;
    margin-top: 248px;
    padding-bottom: 30px;
    height: 100%;
    align-content: start;
    padding-bottom: 20px;
}

.left-main .left-row .contact {
    margin-top: 40px;
    margin-bottom: 20px;
    height: fit-content;
}

.left-main .left-row .contact h2 {
    margin-bottom: 40px;
}

.left-main .left-row .contact ul {
    list-style-type: none;
    padding-left: 0;
}

.left-main .left-row .contact .address-wrap {
    display: flex;
    align-items: center;
}

.left-main .left-row .contact p {
    margin-bottom: 0;
}

.left-main .left-row .contact .address-wrap .icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    width: 30px;
    height: 45px;
    background: #F6D637;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
}

.left-main .left-row .contact .contact-in-wrap {
    display: flex;
    align-items: center;
}

.left-main .left-row .contact .contact-in-wrap .icons-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    margin-top: 20px;
    width: 30px;
    background: #F6D637;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    padding: 7px 0;
}

.left-main .left-row .contact .contact-in-wrap .icons-wrap>li {
    padding: 3px 0;
}

.left-main .left-row .contact .contact-in-wrap .det-wrap {
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-left: 8px;
    margin-top: 20px;
    padding: 7px 0;
}

.left-main .left-row .contact .contact-in-wrap .det-wrap>li {
    padding: 3px 0;
}

.left-main .left-row .edu-wrap {
    margin: 20px 0;
    height: fit-content;
}

.left-main .left-row .edu-wrap h2 {
    margin-bottom: 40px;
}

.left-main .left-row .edu-wrap .edu-details {
    margin: 25px 0;
}

.left-main .left-row .edu-wrap .edu-details p {
    margin-bottom: 2px;
}

.left-main .left-row .projects-wrap {
    height: 150px;
}
.left-main .left-row .strengths-wrap {
    height: 150px;
}




.right-main {
    margin-top: 70px;
    padding-bottom: 20px;
}

.right-main .personal-det {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 2px solid #F6D637;
}

.right-main .exp-wrap {
    margin-top: 30px;
}

.right-main h2 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.right-main .exp-wrap .job-exp p {
    margin: 3px 0;
    font-weight: 500;
}


.right-main .exp-wrap .job-exp .p-wrap {
    display: flex;
    align-items: center;
}

.right-main .exp-wrap .job-exp .p-wrap .line {
    width: 20px;
    height: 85px;
    background-color: #F6D637;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.right-main .exp-wrap .job-exp .p-wrap p {
    margin-left: 30px;
    font-weight: unset;
    padding-left: 20px;
    margin: 20px 0;

}

.right-main .skills {
    margin-top: 30px;
}

.right-main .skills .skill-det {
    display: flex;
    justify-content: space-between;
    padding-right: 40px;
    align-items: center;
    padding-left: 0;
}

.right-main .skills .skill-det p:last-child {
    align-items: center;
    display: flex;
}

.right-main .skills .skill-det p {
    margin-bottom: 0;
    font-weight: 400;
}



 .languages-wrap .languages-details ul{
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    flex-wrap: wrap;
}

.languages-wrap .languages-details ul>li {
    position: relative;
    margin-right: 20px;
}

.languages-wrap .languages-details ul>li::before{
    content: "";
    position: absolute;
    left: -23px;
    top: 5px;
    width: 10px;
    height: 15px;
    border: 2px solid #F6D637;
    background: #F6D637;
    border-radius: 50%;
}

@media (min-width : 1025px) and (max-width : 1100px) {
    .right-main .skills .skill-det {
        display: flex;
        justify-content: space-between;
        padding-right: 10px;
        align-items: center;
        padding-left: 0;
    }
}
@media (max-width : 570px) {
    .right-main .skills .skill-det {
        padding-right: 0;
        align-items: center;
        padding-left: 0;
    }
}