.contact-wrap .con-det-wrap {
    display: flex;
    align-items: center;
}

.contact-wrap .con-det-wrap .icon-wrap {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 50%;
    object-fit: cover;
    align-items: center;
    justify-content: center;
}