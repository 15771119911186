// font-family: var(--t7Font);
// font-size: var(--subHeadingFontSize);
// font-size: var(--paraFontSize);
// line-height: var(--t7LineHeight);
// color: var(--t7Color);


.template22-sec {
  color: black;
  font-family: var(--t7Font);
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 30px 50px 50px 50px;

  font-family: var(--t7Font) !important;

  p {
    font-family: var(--t7Font) !important;

  }

  ul {
    font-family: var(--t7Font) !important;
  }

  li {
    font-family: var(--t7Font) !important;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      column-gap: 20px;

      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  .heading {
    font-size: 35px;
    line-height: var(--t7LineHeight);
    color: var(--t7Color);
    margin-bottom: 0px !important;
  }

  .status {
    font-size: 18px;
    // font-family: "Poppins";
    // line-height: 100%;
    line-height: var(--t7LineHeight);
    margin-bottom: 0px;
    font-weight: bold;
  }

  .sub-heading {
    padding: 10px;
    border-radius: 10px;
    background-color: #f2f2f2;
    font-size: var(--subHeadingFontSize);
    line-height: var(--t7LineHeight);
    font-weight: bold;
    margin: 0;
    color: var(--t7Color);
    margin-bottom: 10px;
  }

  .para,
  a {
    font-size: var(--paraFontSize);
    margin: 0px;
    color: black;
    line-height: var(--t7LineHeight) !important;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  .bold-para {
    font-weight: bold;
    font-family: var(--t7Font);
  }

  .top {
    display: flex;
    flex-direction: column;
    column-gap: 30px;

    .ist {
      display: flex;
      flex-direction: column;
      row-gap: 5px;

      div {
        display: flex;
        align-items: center;
        column-gap: 10px;
      }
    }

    p {
      font-family: var(--t7Font);
    }
  }

  .borderline {
    width: 100%;
    height: 2px;
    background-color: rgb(105, 105, 105);
  }

  ul {
    padding-left: 20px;

    li {
      list-style: disc;
      // font-size: 14px;
      font-size: var(--paraFontSize);
      margin: 0px;
      line-height: var(--t7LineHeight);
    }
  }

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .education,
  .work-experience {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .iconColor {
    color: var(--t7Color);
  }

  .para {
    p {
      font-size: var(--paraFontSize);
      margin: 0px;
      line-height: var(--t7LineHeight) !important;
    }
  }

  .skill {
    ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 15px;
    }
  }

  .interests {
    ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 15px;
    }
  }
}