.two-section {
  .accord {
    .accordion-item {
      border: none;
      margin-bottom: 16px;
      border-radius: 8px;
      background: var(--glass,
          linear-gradient(180deg,
            rgba(255, 255, 255, 0.08) 0%,
            rgba(255, 255, 255, 0.08) 100%));

      .accordion-button {
        width: 100%;
        height: 69px;
        background: #fff;
        // opacity: 0.5;
        color: #343333;
        font-size: 20px;
        font-weight: 400;
        border-radius: 8px;
        box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);

        &::after {
          background-image: url("../../assets/icons/down.svg");
          background: url("../../assets/icons/down.svg");
          content: "";
        }
      }
    }
  }

  .add-new-section {
    .new {
      display: flex;
      justify-content: space-between;
      gap: 30px;

      .left1 {
        padding: 28px 0px 21px 51px;
        width: 100%;
        border-radius: 8px;
        background: var(--purple-dark);
        display: flex;
        flex-direction: column;
        gap: 15px;
      }
    }
  }
}

.coverLetterPreview {
  .quill {
    padding: 0;
  }
}

@media screen and (max-width: 600px) {
  .two-section .add-new-section .new {
    flex-direction: column;
  }
}