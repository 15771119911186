.dreamjob {
  //   border: 1px solid red;
  //   height: 100vh;
  .dreamjob-content {
    width: 100%;
    padding: 100px 140px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 124px 190px;
    .AI {
      border-radius: 8px;
      width: 100%;
      height: 201px;
      padding: 18px 36px;
      background: var(
        --glass,
        linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.08) 0%,
          rgba(255, 255, 255, 0.08) 100%
        )
      );
      .AI-img {
        margin-right: 25px;
      }
      .AI-data {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 1900px) {
  .dreamjob {
    .dreamjob-content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 100px 100px;
    }
  }
}
@media screen and (max-width: 1700px) {
  .dreamjob {
    .dreamjob-content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px 20px;
      align-items: center;
      padding: 40px;
    }
  }
}
@media screen and (max-width: 1400px) {
  .dreamjob {
    .dreamjob-content {
      .AI {
        .AI-data {
          width: 100%;
          h4 {
            font-size: 22px;
          }
        }
      }
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 40px 40px;
      align-items: center;
      padding: 60px;
    }
  }
}
@media screen and (max-width: 1200px) {
  .dreamjob {
    .dreamjob-content {
      .AI {
        .AI-data {
          width: 250px;
          .new-head {
            font-size: 20px;
            .new-para {
              font-size: 16px;
            }
          }
        }
      }
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 40px 40px;
      align-items: center;
      padding: 60px;
    }
  }
}
@media screen and (max-width: 992px) {
  .dreamjob {
    h3 {
      font-size: 17px;
      margin: 0;
    }
    p {
      font-size: 14px;
    }
    .dreamjob-content {
      .AI {
        .AI-data {
          width: 240px;
        }
      }
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 40px 40px;
      align-items: center;
    }
  }
}
@media screen and (max-width: 600px) {
  .dreamjob {
    padding: 20px;
    h3 {
      font-size: 19px;
      margin: 0;
    }
    h4 {
      font-size: 20px;
    }
    p {
      font-size: 14px;
    }
    .dreamjob-content {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      align-items: center;
      padding: 0;
      margin-top: 30px;
      .AI {
        margin: 0 auto;
        width: 100%;
        padding: 20px;
        height: auto;
        .AI-data {
          width: 100%;
          .new-head {
            font-size: 15px;
          }
        }
      }
    }
  }
}
