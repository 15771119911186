// font-family: var(--t19Font);
// font-size: var(--subHeadingFontSize);
// font-size: var(--paraFontSize);
// line-height: var(--t19LineHeight);
// color: var(--t19Color);

.template19-sec {
  color: black;
  padding: 50px;

  font-family: var(--t19Font) !important;

  p {
    font-family: var(--t19Font) !important;

  }

  ul {
    font-family: var(--t19Font) !important;
  }

  li {
    font-family: var(--t19Font) !important;
  }

  .para,
  a {
    // font-size: 15px;
    font-size: var(--paraFontSize);
    margin: 0px;
    // line-height: 120%;
    line-height: var(--t19LineHeight);
    color: rgb(64, 64, 64);

    p {
      // font-size: 15px;
      font-size: var(--paraFontSize);
      margin: 0px;
      // line-height: 120%;
      line-height: var(--t19LineHeight);
      color: rgb(64, 64, 64);
    }
  }

  .bold-para {
    font-weight: bold;
  }

  .light-para {
    font-weight: 500;
  }

  .top {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    padding-bottom: 20px;

    div {
      display: flex;
      align-items: center;
    }

    .heading {
      font-size: 30px;
      // font-size: var(--subHeadingFontSize);
      // font-family: "Poppins";
      font-family: var(--t19Font);
      // line-height: 100%;
      line-height: var(--t19LineHeight);
      color: var(--t19Color);
    }

    .status {
      font-size: 20px;
      // font-size: var(--subHeadingFontSize);
      // font-family: "Poppins";
      font-family: var(--t19Font);
      // color: var(--t19Color);
      // line-height: 100%;
      line-height: var(--t19LineHeight);
      margin-bottom: 0px;
      font-weight: bold;
    }
  }

  .summary {
    padding: 20px 0px;
    border-bottom: 3px dotted gray;
    border-top: 3px dotted gray;
  }

  .bottom {
    display: flex;
    column-gap: 40px;
    padding: 30px 0px;

    .sub-heading {
      // font-size: 20px;
      font-size: var(--subHeadingFontSize);
      margin: 0px;
      // line-height: 100%;
      line-height: var(--t19LineHeight);
      font-weight: bold;
      color: var(--t19Color);
    }

    .right {
      border-left: 4px dotted grey;
      padding-left: 20px;
      width: 45%;
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      .details-sec,
      .skill-sec,
      .strength-sec,
      .award-sec,
      .interest-sec,
      .language-sec {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        div {
          display: flex;
          flex-direction: column;
          row-gap: 4px;
        }
      }
    }

    .left {
      border-left: 4px dotted grey;
      padding-left: 20px;
      width: 55%;
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      .profile-sec,
      .expereince-sec,
      .project-sec,
      .volunteer-sec,
      .education-sec,
      .certificate-sec,
      .publication-sec,
      .reference-sec {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .ist {
          display: flex;
          flex-direction: column;
          row-gap: 4px;
        }
      }

      .reference-sec {
        border: none;
      }
    }
  }
}