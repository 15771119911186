.A-one {
  .strength {
    width: 100%;
    // height: 795px;
    border-radius: 8px;
    background: #fff;
    padding: 0px;

    .suggestion {
      position: relative;

      .suggestion-content {
        .content {
          p {
            margin-right: 23px;
          }
        }
      }
    }

    .writer-btn {
      position: absolute;
      right: 0%;
    }
  }
}

@media screen and (max-width: 1300px) {
  .A-one {
    .strength {
      .new-item-phone {
        flex-direction: column;

        .input-box {
          width: 100% !important;
        }
      }

      .new-item {
        width: 100%;

        .input-box {
          width: 100% !important;
        }
      }

      .suggestion {
        .suggestion-content {
          .content {
            display: flex;
            gap: 10px;
            margin-right: 10px;

            p {
              margin-right: 0;
            }
          }

          .writer-btn {
            position: relative;

            button {
              min-width: 100px !important;
            }
          }
        }
      }
    }
  }
}
