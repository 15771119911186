.toolbox {
  padding: 14px 0px 0 0px;

  .inside-toolbox {
    width: 100%;
    margin-top: 52px;
    padding: 16px 42px 48px 27px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);
    .bottom {
      margin-top: 19px;
      margin-left: 44px;
      .bar {
        height: 9px;
        border-radius: 26px;
        background: linear-gradient(
          90deg,
          #ff5f58 0%,
          #ff9234 32.78%,
          #deb326 69.79%,
          #25a46b 100%
        );
        position: relative;
        .bar-value {
          height: 20px;
          width: 3px;
          background-color: white;
          position: absolute;
          left: 50%;
          top: -5px;
        }
      }
    }
  }
  p {
    color: black;
  }
  ul {
    color: black;
  }
}

@media screen and (max-width: 1600px) {
  .toolbox .inside-toolbox .bottom {
    margin-top: 19px;
    margin-left: 20px;
  }
}
@media screen and (max-width: 600px) {
  .toolbox .inside-toolbox .bottom {
    margin-top: 10px;
    margin-left: 0px;
  }
}
