// font-family: var(--t18Font);
// font-size: var(--subHeadingFontSize);
// font-size: var(--paraFontSize);
// line-height: var(--t18LineHeight);
// color: var(--t18Color);

.template18-sec {
  padding: 50px;
  color: black;

  font-family: var(--t18Font) !important;

  p {
    font-family: var(--t18Font) !important;

  }

  ul {
    font-family: var(--t18Font) !important;
  }

  li {
    font-family: var(--t18Font) !important;
  }

  .sub-heading {
    // font-size: 20px;
    font-size: var(--subHeadingFontSize);
    margin: 0px;
    // line-height: 100%;
    line-height: var(--t18LineHeight);

    // color: #dbb1a0;
    color: var(--t18Color)
  }

  .para,
  a {
    // font-size: 15px;
    font-size: var(--paraFontSize);
    margin: 0px;
    // line-height: 120%;
    line-height: var(--t18LineHeight);

    color: black;

    p {
      // font-size: 15px;
      font-size: var(--paraFontSize);
      margin: 0px;
      // line-height: 120%;
      line-height: var(--t18LineHeight);

      color: black;
    }
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  .bold-para {
    font-weight: bold;
    color: black;
  }

  .top {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    border-bottom: 2px dotted black;
    padding-bottom: 20px;

    .para {
      // color: #dbb1a0;
      color: var(--t18Color)
    }

    .heading {
      font-size: 35px;
      // font-size: var(--subHeadingFontSize);
      // font-family: "Poppins";
      font-family: var(--t18Font);
      // line-height: 100%;
      line-height: var(--t18LineHeight);

      font-weight: bold;
    }

    .status {
      font-size: 22px;
      font-size: var(--subHeadingFontSize);
      // font-family: "Poppins";
      font-family: var(--t18Font);
      // line-height: 100%;
      line-height: var(--t18LineHeight);

      margin-bottom: 0px;
    }

    div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      column-gap: 10px;
    }
  }

  .profile-sec,
  .education-sec,
  .certificate-sec,
  .skill-sec,
  .experience-sec,
  .project-sec,
  .volunteer-sec,
  .publication-sec,
  .reference-sec,
  .award-sec,
  .interest-sec {
    display: flex;
    align-items: flex-start;
    column-gap: 20px;
    padding: 10px 10px 20px 0px;
    border-bottom: 2px dotted black;

    .content {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .ist {
        display: flex;
        flex-direction: column;
        row-gap: 3px;
      }
    }
  }

  .skill-sec,
  .interest-sec {
    .content {
      width: 100%;
    }

    .second {

      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 15px;

    }
  }

  .interest-sec {
    border: none;
  }
}