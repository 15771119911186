// font-family: var(--t16Font);
// font-size: var(--subHeadingFontSize);
// font-size: var(--paraFontSize);
// line-height: var(--t16LineHeight);
// color: var(--t16Color);

.template16-sec {
  color: black;
  padding: 0px 0px 50px 0px;
  background-color: white;
  // font-family: Arial;
  font-family: var(--t16Font);

  font-family: var(--t16Font) !important;

  p {
    font-family: var(--t16Font) !important;

  }

  ul {
    font-family: var(--t16Font) !important;
  }

  li {
    font-family: var(--t16Font) !important;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 40px;
    padding: 50px;
    // background-color: rgb(1, 142, 213);
    background-color: var(--t16Color);

    border-bottom: 4px solid #3e3f3d;

    .right {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;
      width: 70%;
      gap: 5px;

      .heading {
        font-size: 30px;
        // font-size: var(--subHeadingFontSize);

        // font-family: "Poppins";
        font-family: var(--t16Font);

        // line-height: 100%;
        line-height: var(--t16LineHeight);

        color: white;
      }

      .status {
        color: white;
        font-size: 18px;
        // font-size: var(--paraFontSize);

        // font-family: "Poppins";
        // line-height: 100%;
        line-height: var(--t16LineHeight);

        margin-bottom: 0px;
        font-weight: bold;
      }
    }

    .left {
      width: 30%;

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }

  .bottom {
    display: flex;
    column-gap: 40px;
    justify-content: flex-start;
    padding: 30px 50px;

    .left {
      width: 30%;
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      .details-sec {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        .para {
          width: 85%;
        }

        div {
          display: flex;
          column-gap: 10px;
        }

        .sub-heading {
          // font-size: 16px;
          font-size: var(--subHeadingFontSize);

          margin: 0px;
          font-weight: bold;
        }
      }

      .skill-sec,
      .award-sec,
      .interest-sec {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        div {
          display: flex;
          flex-wrap: wrap;
          gap: 4px;

          .para {
            width: fit-content;
            color: white;
            padding: 5px;
            border-radius: 5px;
            // background-color: rgb(1, 142, 213);
            background-color: var(--t16Color);

          }
        }

        .sub-heading {
          // font-size: 16px;
          font-size: var(--subHeadingFontSize);

          margin: 0px;
          font-weight: bold;
        }
      }
    }

    .right {
      width: 30%;
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      .profile-sec,
      .expereince-sec,
      .project-sec,
      .volunteer-sec,
      .education-sec,
      .certificate-sec,
      .publication-sec,
      .reference-sec {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .sub-heading {
          // font-size: 20px;
          font-size: var(--subHeadingFontSize);

          margin: 0px;
          // line-height: 100%;
          line-height: var(--t16LineHeight);

          font-weight: bold;
        }

        .ist {
          display: flex;
          flex-direction: column;
          row-gap: 4px;
        }
      }

      .reference-sec {
        border: none;
      }
    }
  }

  .para,
  a {
    // font-size: 15px;
    font-size: var(--paraFontSize);

    margin: 0px;
    // line-height: 120%;
    line-height: var(--t16LineHeight);

    color: rgb(64, 64, 64);
    word-wrap: break-word;
    width: 100%;

    p {
      // font-size: 15px;
      font-size: var(--paraFontSize);

      margin: 0px;
      // line-height: 120%;
      line-height: var(--t16LineHeight);

      color: rgb(64, 64, 64);
      word-wrap: break-word;
      width: 100%;

    }
  }

  .bold-para {
    font-weight: bold;
    // color: rgb(1, 142, 213);
    color: var(--t16Color);

  }

  .light-para {
    font-weight: 500;
  }
}