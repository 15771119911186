.template9-sec {
  color: black;
  font-family: var(--t9Font);
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 30px 50px 50px 50px;

  font-family: var(--t9Font) !important;

  p {
    font-family: var(--t9Font) !important;

  }

  ul {
    font-family: var(--t9Font) !important;
  }

  li {
    font-family: var(--t9Font) !important;
  }

  ul {
    padding-left: 20px;

    li {
      list-style: disc;
      font-size: var(--paraFontSize);
      margin: 0px;
      line-height: var(--t9LineHeight);
    }
  }

  .heading {
    font-size: 35px;
    line-height: var(--t9LineHeight);
    margin-bottom: 10px;
    text-align: center;
  }

  .line {
    background-color: rgb(0, 0, 0);
    width: 100%;
    height: 1px;
  }

  span {
    color: var(--t9Color)
  }

  .sub-heading {
    font-size: var(--subHeadingFontSize);
    line-height: var(--t9LineHeight);
    font-weight: bold;
    color: var(--t9Color);
    margin: 0;
  }

  .para {
    font-size: var(--paraFontSize);
    margin: 0px;
    line-height: var(--t9LineHeight);

    p {
      font-size: var(--paraFontSize);
      margin: 0px;
      line-height: var(--t9LineHeight);
    }
  }

  .bold-para {
    font-weight: bold;
  }

  .light-para {
    font-weight: 600;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;

    div {
      display: flex;
      align-items: center;
      column-gap: 5px;
    }
  }

  .profile-sec,
  .education-sec,
  .experience-sec {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .skill-sec {
    display: flex;
    justify-content: space-between;
    column-gap: 50px;

    .left,
    .right {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .ist {
        column-gap: 25px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  .interest-sec {
    ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}