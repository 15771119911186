.personal-info {
  width: 100%;
  //   height: 1149px;
  border-radius: 8px;
  background: #fff;
  padding: 56px 29px;
  .top-content {
    .left {
      margin-right: 36px;
      .profile-img {
        img {
          padding: 11px;
          border: 1px solid red;
          width: 102px;
          height: 102px;
          border-radius: 100%;
          object-fit: cover;
        }
      }
    }
    .right {
      width: 100%;
    }
  }

  .btm {
    .add-custom-field {
      margin-top: 40px;
      border-radius: 4%;
      border: 1px dashed #1e2882;

      button {
        color: #1e2882;
        background: #fff;
      }
    }
    .writer-btn {
      margin-top: 40px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .personal-info {
    .top-content {
      align-items: flex-start;
      .right {
        .input-box {
          flex-direction: column;
          label {
            width: 100% !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .personal-info {
    .top-content {
      flex-direction: column;
      align-items: center;
      .left {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
    .btm {
      .input-box {
        flex-direction: column;
        display: flex !important;
      }
    }
  }
}
