.resume-faq {
  padding-bottom: 140px;
  .faq-accordian {
    max-width: 1342px;
    margin: 0 auto;
    background: transparent;
    border-radius: none !important;
    .accordion-item {
      background: transparent;
      border: none;
      color: var(--white);
      border-bottom: 1px solid var(--white);

      button {
        font-size: 20px;
        color: var(--white);
        box-shadow: none;
        padding: 17px 10px 30px 0px;
        border-radius: none;
        background: transparent;
        &::after {
          border: none;
          background-image: url("../../assets/icons/cheveron-down-small-white.svg");
        }
      }
    }
    .accordion-body {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
@media screen and (max-width: 1600px) {
  .resume-faq .faq-accordian {
    max-width: 1200px;
  }
}
@media screen and (max-width: 1500px) {
  .resume-faq .faq-accordian {
    max-width: 900px;
  }
}
@media screen and (max-width: 992px) {
  .resume-faq .faq-accordian {
    max-width: 700px;
  }
}
@media screen and (max-width: 600px) {
  .resume-faq {
    padding: 20px;
    h1 {
      font-size: 20px;
      margin-bottom: 0 !important;
    }
    .faq-accordian {
      max-width: 350px;
      .accordion-item button {
        font-size: 15px;
      }
    }
  }
}
