.fix {
  margin-top: 100px;
  padding-bottom: 165px;

  .one {
    position: relative;
    z-index: 2;

    h2 {
      color: #fff;
      font-family: "Poppins";
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    p {
      color: #fff;
      text-align: center;
      font-family: "Poppins";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .input-box {
      margin-top: 38px;
      width: 583px;
      display: flex;
      align-items: center;
      justify-content: center;

      label {
        width: 100%;

        input {
          height: 53px;

          &::placeholder {
            color: #c8c8c8;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        textarea {
          padding-top: 12px;
          outline: none;

          &::placeholder {
            color: #c8c8c8;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      .review-btn {
        width: 100%;

        .btnOuterWrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 10px;

          button {
            width: 30%;
            height: 40px;
          }
        }

      }
    }
  }

  .two {
    position: absolute;
    z-index: 1;
    top: 8%;

    img {
      border-radius: 295.103px;
      filter: blur(75px);
    }
  }
}

.fixReviewContainer {
  .fixReviewCard {
    h4 {
      font-size: 18px;
      margin-bottom: 10px;
    }

    width: 550px;
    padding: 20px 40px;
    background-color: #fff;
    color: black;
    border-radius: 10px;

    &:hover {
      background-color: #ececec;
    }
  }
}

@media screen and (max-width: 600px) {
  .fix {
    padding: 20px;
    margin-top: 50px;

    .one {
      h2 {
        font-size: 24px;
      }

      width: 100%;

      .input-box {
        width: 100%;

        .review-btn {
          button {
            width: 100%;
          }
        }

        .review-btn {
          width: 100%;

          .btnOuterWrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;

            button {
              width: 70%;
              height: 40px;
            }
          }

        }
      }
    }

    .two {
      width: 100%;

      .shade-img {
        display: none;
      }
    }
  }
}