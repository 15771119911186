.privacyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .privacyHeading {


        h1 {
            font-size: 26px;
        }
    }

    .privacyContent {
        h1 {
            font-size: 26px;
            font-weight: 800;

        }

        h2 {
            font-size: 22px;
            margin-top: 20px;
            font-weight: 800;

        }

        h3 {
            font-size: 18px;
            font-weight: 800;
        }

        width: 70%;

        font-size: 26px;

        li {
            font-size: 16px;
        }
    }
}