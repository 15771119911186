.money {
  padding: 42px 73px 95px 115px;
  .three-cards {
    padding: 62px 73px;
    gap: 100px;
  }
  .card-1 {
    width: 334px;
    min-height: 723px;
    height: fit-content;
    border-radius: 8px;

    background: var(
      --glass,
      linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.08) 0%,
        rgba(255, 255, 255, 0.08) 100%
      )
    );
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.3);
  }
  .top {
    padding: 42px 12px 0px 13px;
    border-bottom: 1px solid;
  }
  .bottom {
    padding: 41px 13px 0px 13px;
    .new-para-2 {
      color: white !important;
      img {
        margin-right: 15px;
      }
    }
    .extra {
      padding: 8px 14px 18px 12px;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      background: #210a4c;
      border: 1px solid #210a4c;
      .new-para-2 {
        // margin-left: 25px;
      }
      p{
        font-size: 14px;
        margin-left: 25px;
      }
    }
  }
  p {
    color: white !important;
  }
}

@media screen and (max-width: 1800px) {
  .money .three-cards {
    padding: 62px 0px;
    gap: 100px;
  }
}
@media screen and (max-width: 1400px) {
  .money {
    padding: 42px 60px 95px 60px;
    .three-cards {
      padding: 62px 0px;
      gap: 30px;
    }
    .card-1 {
      // width: 300px;

      h3 {
        font-size: 28px;
      }
      p {
        font-size: 14px;
      }
      .dollar {
        margin-top: 32px !important;
      }
      h2 {
        font-size: 32px;
      }
      .upgrade-btn {
        margin-top: 32px !important;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .money {
    padding: 42px 30px 95px 30px;
    .three-cards {
      padding: 62px 0px;
      gap: 30px;
    }
    .card-1 {
      // width: 300px;

      h3 {
        font-size: 24px;
      }
      p {
        font-size: 13px;
      }
      .dollar {
        margin-top: 32px !important;
      }
      h2 {
        font-size: 32px;
      }
      .upgrade-btn {
        margin-top: 32px !important;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .money {
    padding: 0px 30px 0px 30px;
    .three-cards {
      padding: 30px 0px;
      gap: 30px;
      flex-direction: column;
      align-items: center;

      .card-1 {
        margin-top: 20px !important;
      }
    }
    .heading {
    }
  }
}
@media screen and (max-width: 600px) {
  .money {
    .heading {
      h1 {
        font-size: 22px;
        &:first-child {
          font-size: 20px;
        }
      }
    }
    .three-cards {
      .card-1 {
        width: 100%;
      }
    }
  }
}
