// font-family: var(--t17Font);
// font-size: var(--subHeadingFontSize);
// font-size: var(--paraFontSize);
// line-height: var(--t17LineHeight);
// color: var(--t17Color);

.templat17-sec {

  font-family: var(--t17Font) !important;

  p {
    font-family: var(--t17Font) !important;

  }

  ul {
    font-family: var(--t17Font) !important;
  }

  li {
    font-family: var(--t17Font) !important;
  }

  .sub-heading {
    font-size: 20px;
    font-size: var(--subHeadingFontSize);

    margin: 0px;
    line-height: 100%;
    color: black;
  }

  .para,
  a {
    // font-size: 15px;
    font-size: var(--paraFontSize);

    margin: 0px;
    // line-height: 120%;
    line-height: var(--t17LineHeight);

    color: rgb(139, 139, 139);
    word-wrap: break-word;

    p {
      // font-size: 15px;
      font-size: var(--paraFontSize);

      margin: 0px;
      // line-height: 120%;
      line-height: var(--t17LineHeight);

      color: rgb(139, 139, 139);
      word-wrap: break-word;

    }
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  .bold-para {
    font-weight: bold;
    color: black;
  }

  .dark-para {
    font-weight: 600;
    color: black;
  }

  .light-para {
    font-weight: 400;
    color: black;
  }

  .header {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    row-gap: 10px;
    padding: 60px 0px;
    background: url("/style/images/temp-back.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .heading {
      font-size: 35px;
      // font-family: "Poppins";
      font-family: var(--t17Font);
      // line-height: 100%;
      line-height: var(--t17LineHeight);

      color: white;
      font-weight: bold;
    }

    .status {
      color: white;
      font-size: 22px;
      font-size: var(--subHeadingFontSize);

      // font-family: "Poppins";
      font-family: var(--t17Font);
      // line-height: 100%;
      line-height: var(--t17LineHeight);

      margin-bottom: 0px;
    }
  }

  .temp-body {
    display: flex;
    column-gap: 30px;
    padding: 30px 60px;
    justify-content: space-between;

    .left {
      width: 30%;
      display: flex;
      flex-direction: column;
      row-gap: 30px;

      img {
        object-fit: cover;
      }

      .skill-sec,
      .interest-sec {
        display: flex;
        flex-direction: column;
        row-gap: 13px;

        .ist {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
        }
      }

      .contact-sec,
      .education-sec,
      .certificate-sec,
      .award-sec {
        display: flex;
        flex-direction: column;
        row-gap: 13px;

        .ist {
          display: flex;
          flex-direction: column;
          row-gap: 4px;
        }
      }

      img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        // border: 2px solid rgb(0, 210, 230);
        border: 2px solid var(--t17Color);
      }
    }

    .right {
      width: 70%;
      display: flex;
      flex-direction: column;
      row-gap: 30px;

      .about-sec,
      .experience-sec,
      .project-sec,
      .volunteer-sec,
      .publication-sec,
      .reference-sec {
        display: flex;
        flex-direction: column;
        row-gap: 13px;

        .ist {
          display: flex;
          flex-direction: column;
          row-gap: 4px;
        }
      }
    }
  }
}