.how-work {
  overflow: hidden;
  position: relative;

  .working-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 140px;
    margin: 0 auto;
    position: relative;
    max-width: 1600px;

    .tab-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: fit-content;
      width: 270px;
      min-height: 215px;

      p {
        margin-top: 42px;
        color: #fff;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
      }

      button {
        background: white;
        color: #ac50c6;
        width: 85px;
        height: 85px;
        border-radius: 42px;
        border: 3px solid #ac50c6;
        color: #ac50c6;
        font-size: 36px;
        font-weight: 600;

        &:hover {
          background-color: #ac50c6;
          color: white;
        }

        &.tab-active {
          background: #ac50c6;
          color: white;
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 90px;
      width: 100%;
      height: 3px;
      border-top: 3px dashed #ac50c6;
      z-index: -1;
    }
  }

  .slide-in {
    // transform: translateX(0);
    max-width: 100%;
    overflow: visible;
    // transition: all 0.3s linear;
  }

  .slide-out {
    // transform: translateX(3000px);
    max-width: 0;
    overflow: hidden;
    width: 0;
    height: 0;
    // transition: all 0.3s linear;
  }

  .working-tab-content {
    .slide-in {
      .content {
        display: flex;
        // justify-content: space-around;
        gap: 180px;

        .left {
          width: 500px;

          video {
            width: 100%;
          }

          img {
            width: 100%;
          }
        }

        .right {
          .top {
            span {
              color: #fff;
              font-size: 24px;
              font-weight: 600;
              display: flex;
              flex-direction: column;
              width: 447px;

              &:last-child {
                color: #fff;
                font-size: 18px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1650px) {
  .how-work .working-tabs {
    gap: 90px;
    max-width: 1360px;
  }
}

@media screen and (max-width: 1400px) {
  .how-work .working-tabs {
    gap: 40px;
    max-width: 1000px;

    .tab-btn {
      p {
        font-size: 18px;
      }
    }
  }
}

.working-tab-content {
  .slide-in {
    .content {
      gap: 40px !important;
    }
  }
}


@media screen and (max-width: 600px) {
  .how-work {
    padding-bottom: 50px;

    .working-tabs {
      gap: 0px;
      max-width: 1000px;
      padding: 0px 10px;

      &::after {
        top: 67px;
      }

      .tab-btn {
        button {
          width: 40px;
          height: 40px;
          font-size: 20px;
        }

        p {
          font-size: 10px;
          width: 80px;
        }
      }
    }

    .working-tab-content {
      .slide-in {
        .content {
          display: flex !important;
          flex-direction: column-reverse;
          margin-top: 0px !important;

          .right {
            .top {
              span {
                width: 300px;
                font-size: 18px;

                &:last-child {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}