@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.temp26 {
  font-family: var(--t26Font);
  color: #545454;

  h1 {
    font-size: var(--subHeadingFontSize);
    font-weight: 400;
    color: #545454;
    line-height: var(--t26LineHeight);
    font-family: var(--t26Font);
  }

  h3 {
    font-size: var(--paraFontSize);
    font-weight: 400;
    line-height: 23px;
    line-height: var(--t26LineHeight);
    font-family: var(--t26Font);
  }

  h6 {
    font-size: var(--subHeadingFontSizethree);
    //   font-weight: 400;
    line-height: 19px;
    color: #545454;
    line-height: var(--t26LineHeight);
    font-family: var(--t26Font);
  }

  p {
    font-size: var(--subHeadingFontSizefour);
    font-weight: 400;
    line-height: 20px;
    color: #545454;
    line-height: var(--t26LineHeight);
    font-family: var(--t26Font);
  }

  .p2 {
    font-size: var(--subHeadingFontSizefive);
    font-weight: 400;
    line-height: 17px;
    color: #595959;
    margin-bottom: 0;
    line-height: var(--t26LineHeight);
    font-family: var(--t26Font);
  }

  ul {
    font-size: var(--subHeadingFontSizesix) !important;
    line-height: var(--t26LineHeight);
    font-family: var(--t26Font);
  }

  li {
    font-size: var(--subHeadingFontSizesix) !important;
    line-height: var(--t26LineHeight);
    font-family: var(--t26Font);
  }
}

.temp26 {
  .header {
    padding: 20px 50px;
    width: 100%;
    display: flex;
    border-bottom: 8px solid #545454;
    background: var(--t26Color);

    .left {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      h1 {
        border-bottom: 2px solid #545454;
        width: 100%;
        padding-bottom: 8px;
      }
    }

    .right {
      width: 50%;
      //   background: blue;
      display: flex;
      align-items: end;
      flex-direction: column;

      .content {
        padding-bottom: 10px;

        p {
          display: flex;
          align-items: center;
          font-size: 18px;

          span {
            padding-right: 15px;
          }
        }
      }
    }
  }

  .summary-content {
    padding: 20px 50px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;

      h3 {
        width: 50%;
        text-align: center;
        border-bottom: 2px solid #949494;
        padding-bottom: 10px;
      }
    }

    .bottom {
      display: flex;
      width: 100%;
      margin-top: 30px;

      .left {
        // background: red;
        width: 50%;
        border-right: 2px solid #949494;

        .content {
          h3 {
            padding-bottom: 10px;
            border-bottom: 2px solid #949494;
            width: 35%;
          }

          .two {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding-right: 20px;
            margin-top: 20px;

            h6 {
              word-break: break-all;
            }

            p {
              word-break: break-all;
            }
          }

          .skill-list {
            margin-top: 20px;

            p {
              word-break: break-all;
            }

            ul {
              padding-left: 20px;

              li {
                list-style-type: disc !important;
                padding-right: 30px;
                font-size: 14px;
                word-break: break-all;
              }
            }
          }
        }
      }

      .right {
        width: 50%;
        padding-left: 20px;

        .content {
          h3 {
            padding-bottom: 10px;
            border-bottom: 2px solid #949494;
            width: 100%;
          }

          .two {
            display: flex;
            flex-direction: column;
            gap: 10px;
            // padding-right: 20px;
            margin-top: 20px;

            p {
              word-break: break-all;
            }

            h6 {
              word-break: break-all;
            }

            ul {
              padding-left: 20px;

              li {
                list-style-type: disc !important;
                padding-right: 20px;
                font-size: 14px;
                word-break: break-all;
              }
            }
          }
        }
      }
    }
  }
}

// Media Queries
@media screen and (max-width: 1700px) {
  .temp26 {
    .header {
      padding: 40px;

      .left {
        h1 {
          font-size: 40px;
          width: 100%;
        }
      }
    }

    .summary-content {
      padding: 40px;

      .bottom {
        .right {
          .content {
            h3 {
              font-size: 23px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .temp26 {
    .header {
      padding: 35px;

      .left {
        h1 {
          font-size: 35px;
        }

        h4 {
          font-size: 23px;
        }
      }

      .right {
        .content {
          h5 {
            font-size: 16px;
          }
        }
      }
    }

    .summary-content {
      padding: 35px;

      .bottom {
        .right {
          .content {
            .two {
              ul {
                li {
                  padding-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .temp26 {
    .header {
      display: flex;
      flex-direction: column;
      padding: 25px;

      .left {
        width: 100%;
      }

      .right {
        width: 100%;
      }
    }

    .summary-content {
      padding: 25px;

      .bottom {
        .left {
          .content {
            h3 {
              font-size: 18px;
              width: 60%;
            }
          }
        }

        .right {
          .content {
            h3 {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}