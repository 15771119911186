.templates {
  padding: 18px 70px 70px 70px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .search-div {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    column-gap: 30px;

    .search-icon {
      width: 24px;
      height: 24px;
    }

    input {
      width: 100%;
      border: none;
      outline: none;
      font-family: "Poppins", monospace;
      color: black;
      font-size: 20px;
      font-weight: 400;
      line-height: normal;
    }
  }

  .temp-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 50px;

    p {
      font-family: "Roboto", monospace;
      color: rgb(255, 255, 255);
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
      margin: 0px;
      padding: 10px;
      cursor: pointer;

      &.active {
        background-color: #4d79ff;
        border-radius: 5px;
      }
    }
  }

  .templates-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;

    .template-card {
      width: 100%;
      position: relative;
      max-width: 320px;
      max-height: 519px;
      overflow: hidden;

      .cv-inner {
        transform: scale(0.4);
        transform-origin: left top;
        width: 800px;
        height: 1200px;
      }

      .use-template {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        display: none;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 30px;
      }

      &:hover {
        .use-template {
          display: flex;
          // background: rgba(30, 40, 130, 0.62);
          height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 1650px) {
  .templates {
    .temp-tabs {
      column-gap: 10px;

      p {
        font-size: 17px;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .templates {
    .head {
      font-size: 40px;
    }



    .search-div {
      padding: 15px;
      column-gap: 20px;
    }

    .temp-tabs {
      column-gap: 10px;

      p {
        font-size: 15px;
      }
    }

    .templates-content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media screen and (max-width: 1200px) {
  .templates {
    .search-div {
      padding: 10px;
      column-gap: 10px;

      input {
        font-size: 16px;
      }
    }

    .temp-tabs {
      column-gap: 10px;

      p {
        font-size: 14px;
        padding: 5px;
      }
    }

    .templates-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
    }
  }
}

@media screen and (max-width: 767px) {
  .templates {
    padding: 20px;
    row-gap: 10px;

    .search-div {
      padding: 10px;
      column-gap: 0px;

      .search-icon {
        width: 16px;
        height: 16px;
      }

      input {
        font-size: 14px;

        &::placeholder {
          font-size: 12px;
        }
      }
    }

    .head {
      font-size: 20px;
      line-height: 20px;
    }

    .temp-tabs {
      margin: 10px 0px;
      min-width: 300px;
      overflow-x: auto;

      justify-content: start;

      p {
        min-width: fit-content;
      }
    }

    .templates-content {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-items: center;

      .template-card {
        overflow: hidden;
        width: 90vw !important;

        .cv-inner {
          transform: scale(0.4);

        }

        .use-template {
          display: flex;
          bottom: 20px;
        }
      }
    }
  }

  .cv-inner {
    overflow: hidden;
  }
}

.headHistoryContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .headHistory {
    font-size: 34px;

  }
}