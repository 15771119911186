.template-1 {
  .resumer-header {
    height: 161px;
    width: 100%;
    background-color: #8d4b55;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 80px;

    .left {
      img {
        width: 160px;
        height: 160px;
        width: 217.202px;
        height: 217.202px;
        border-radius: 100%;
        margin-top: 116px;
      }
    }

    .right {
      margin-left: 70px;
    }
  }

  .resume-body {
    padding-left: 50px;
    padding-right: 50px;
    color: #000 !important;
    display: flex;
    justify-content: space-between;

    label {
      font-family: "Poppins";
      font-size: 17.914px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      position: relative;
      text-transform: capitalize;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -3px;
        left: 0;
        background-color: #000;
      }
    }

    ul {
      padding-top: 23px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      li {
        max-width: 280px;

        span {
          margin-left: 20px;
        }

        font-family: "Poppins";
        font-size: 15.674px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .left {
      padding-top: 140px;
    }

    .right {
      padding-top: 80px;
      max-width: 380px;
    }
  }
}

@media screen and (max-width: 1800px) {
  .template-1 {
    .resumer-header {
      height: 140px;
      padding-left: 40px;
      padding-right: 50px;

      .left {
        img {
          width: 150px;
          height: 150px;
          margin-top: 90px;
        }
      }

      .right {
        h1 {
          font-size: 35px;
        }
      }
    }

    .resume-body {
      padding-left: 30px;
      padding-right: 30px;

      label {
        font-size: 14px;
        font-weight: 500;
      }

      ul {
        padding-top: 20px;

        li {
          width: 200px;

          span {
            margin-left: 15px;
          }

          font-size: 13px;
        }

        p {
          font-size: 13px;
        }
      }

      .left {
        padding-top: 100px;
      }

      .right {
        padding-top: 60px;
        max-width: 380px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .template-1 {
    .resumer-header {
      .right {
        h1 {
          font-size: 26px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .template-1 {
    display: none;

    .resumer-header {
      height: 100px;

      .left {
        img {
          width: 120px;
          height: 120px;
          margin-top: 60px;
        }
      }

      .right {
        h1 {
          font-size: 26px;
        }
      }
    }
  }
}

.serifFont {
  font-family: serif !important;

  p {
    font-family: serif !important;
  }

  li {
    font-family: serif !important;
  }
}

.courierFont {
  font-family: 'Courier New', Courier, monospace !important;

  p {
    font-family: 'Courier New', Courier, monospace !important;
  }

  li {
    font-family: 'Courier New', Courier, monospace !important;
  }
}

.lineHeight {
  line-height: var(--size) !important;

  p {
    line-height: var(--size) !important;
  }

  li {
    line-height: var(--size) !important;
  }
}