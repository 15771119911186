.main-t5 .main-container-t5 p{
    font-size: 14px;
    margin: 5px 0;
}
.main-t5 .main-container-t5 h4{
    font-size: 16px;
}

.main-left-t5 {
    color: white;
    background: #29343A;
    padding: 0;
}

.main-left-t5 .personal {
    justify-content: space-between;
    padding-left: 10px;
}

.main-left-t5 .personal .reuse-personal-det {
    writing-mode: vertical-rl;
    height: fit-content;
    transform: rotate(180deg);
}
.main-left-t5 .personal .reuse-personal-det h1{
    font-size: 30px;
}
.main-left-t5 .personal .reuse-personal-det h3{
    font-size: 19px;
}

.main-left-t5 .personal .t5-potrait{
    width: 170px;
    height: 230px;
    overflow: hidden;
    border-top: 8px solid white;
    border-left: 8px solid white;
    border-bottom: 8px solid white;
}

.main-left-t5 .personal .t5-potrait img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.main-t5{
    background: #DDDDDD;
}
.main-t5 .main-container-t5{
    background: white;
    color: black;
}


.main-t5 .main-container-t5 .main-left-t5 .left-t5 > div{
    margin-top: 20px;
    margin-bottom: 20px;

}
.main-t5 .main-container-t5 .main-left-t5 .about{
    margin: 15px 0;
}

.main-t5 .main-container-t5 .icon-wrap{
    background: #62C6DB;
}

.main-t5 .main-container-t5 ul{
    padding-left: 2.75rem;
}

.main-t5 .main-container-t5 .skills-wrap .skills-det-wrap{
    margin-top: 10px;
}
.main-t5 .main-container-t5 .skills-wrap .skills-det-wrap .skill-det{
    display: flex;
    align-items: center;
}
.main-t5 .main-container-t5 .skills-wrap .skills-det-wrap .skill-det p{
    flex: 1;
    margin: 0;
}
.main-t5 .main-container-t5 .skills-wrap .skills-det-wrap .skill-det .pro-bar{
    flex: 1;
}

.main-t5 .main-container-t5 .main-left-t5 .language ul{
    display: flex;
    padding-left: 0;
    padding-top: 8px;
    flex-wrap: wrap;
    justify-content: space-between;
}
.main-t5 .main-container-t5 .main-left-t5 .strength .strength-det{
    height: 200px;
}


.main-t5 .main-container-t5 .main-right-t5 .job-exp-reuse ul > li .exp-h4-wrap h5{
    color: #62C6DB;
}

.main-t5 .main-container-t5 .main-right-t5 .projects .projects-det{
    height: 200px;
}
.main-t5 .main-container-t5 .main-right-t5 .right-t5 > div{
    margin: 20px 0;
}