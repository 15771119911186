.template30 {
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
  line-height: var(--t30LineHeight);

  .t30LineHeight {
    line-height: var(--t30LineHeight);
  }

  h1 {
    font-size: var(--subHeadingFontSize);
    color: black;
    text-transform: uppercase;
    font-family: var(--t30Font);
  }

  h4 {
    font-size: var(--subHeadingFontSizethree);
    color: #545454;
    font-weight: bold;
    text-transform: uppercase;
    font-family: var(--t30Font);
  }

  p {
    font-size: var(--subHeadingFontSizethree);
    color: #464a4e;
    margin-bottom: 0;
    font-family: var(--t30Font);
    line-height: var(--t30LineHeight);
  }

  ul {
    list-style-type: disc !important;
    font-family: var(--t30Font);
  }

  li {
    font-size: var(--paraFontSize);
    color: #464a4e;
    font-family: var(--t30Font);
    padding-bottom: 10px;
  }
}

.template30 {
  padding: 0px 50px 50px 50px;

  .top-line {
    height: 20px;
    background: var(--t30Color);
    opacity: 0.75;

  }

  .name {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .contact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #bfc6ce;
    padding: 10px 40px;

    p {
      span {
        padding-left: 15px;
      }
    }
  }

  .description {
    padding-top: 20px;

    p {
      font-size: 17px;
    }
  }

  .expertise {
    padding-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      align-items: center;
      gap: 50px;

      .heading {
        h4 {
          color: var(--t30Color);
        }
      }

      .line {
        height: 1px;
        width: 100%;
        background: black;
      }
    }

    .three {
      margin-top: 30px;

      .content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        // gap: 10px;
      }
    }
  }

  .experience {
    padding-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      align-items: center;
      gap: 50px;

      .heading {
        h4 {
          color: var(--t30Color);
        }
      }

      .line {
        height: 1px;
        width: 100%;
        background: black;
      }
    }

    .three {
      padding-left: 20px;
      border-left: 1px solid black;
      margin-top: 30px;

      .content {
        display: flex;
        padding-bottom: 20px;

        &:last-child {
          padding-bottom: 0;
        }

        .left {
          width: 40%;
          display: flex;
          flex-direction: column;
          gap: 10px;
          position: relative;

          h4 {
            &::after {
              content: "";
              width: 8px;
              height: 8px;
              border-radius: 20px;
              position: absolute;
              background: black;
              top: 0;
              left: -24px;
            }
          }
        }

        .right {
          width: 60%;
        }
      }
    }
  }

  .volunteer {
    padding-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      align-items: center;
      gap: 50px;

      .heading {
        h4 {
          color: var(--t30Color);
        }
      }

      .line {
        height: 1px;
        width: 100%;
        background: black;
      }
    }

    .three {
      margin-top: 30px;
      display: flex;
      flex-direction: column;

      .content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        .left {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding-bottom: 20px;

          p {
            // word-break: break-all;
          }
        }
      }
    }
  }

  .education {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      align-items: center;
      gap: 50px;

      .heading {
        h4 {
          color: var(--t30Color);
        }
      }

      .line {
        height: 1px;
        width: 100%;
        background: black;
      }
    }

    .three {
      margin-top: 30px;

      .content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        .left {
          display: flex;
          flex-direction: column;
          gap: 10px;

          p {
            // word-break: break-all;
          }
        }
      }
    }
  }

  .certification {
    padding-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      align-items: center;
      gap: 50px;

      .heading {
        h4 {
          color: var(--t30Color);
        }
      }

      .line {
        height: 1px;
        width: 100%;
        background: black;
      }
    }

    .three {
      margin-top: 30px;
      display: flex;
      flex-direction: column;

      .content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;

        .left {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding-bottom: 20px;

          p {
            // word-break: break-all;
          }
        }
      }
    }
  }

  .award {
    padding-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      align-items: center;
      gap: 50px;

      .heading {
        h4 {
          color: var(--t30Color);
        }
      }

      .line {
        height: 1px;
        width: 100%;
        background: black;
      }
    }

    .three {
      margin-top: 30px;
      display: flex;
      flex-direction: column;

      .content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        .left {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding-bottom: 20px;

          p {
            // word-break: break-all;
          }
        }
      }
    }
  }

  .publication {
    padding-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      align-items: center;
      gap: 50px;

      .heading {
        h4 {
          color: var(--t30Color);
        }
      }

      .line {
        height: 1px;
        width: 100%;
        background: black;
      }
    }

    .three {
      margin-top: 30px;
      display: flex;
      flex-direction: column;

      .content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        .left {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding-bottom: 20px;

          p {
            // word-break: break-all;
          }
        }
      }
    }
  }

  .interest {
    padding-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      align-items: center;
      gap: 50px;

      .heading {
        h4 {
          color: var(--t30Color);
        }
      }

      .line {
        height: 1px;
        width: 100%;
        background: black;
      }
    }

    .three {
      margin-top: 30px;

      .content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
      }
    }
  }

  .reference {
    padding-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      align-items: center;
      gap: 50px;

      .heading {
        h4 {
          color: var(--t30Color);
        }
      }

      .line {
        height: 1px;
        width: 100%;
        background: black;
      }
    }

    .three {
      margin-top: 30px;
      display: flex;
      flex-direction: column;

      .content {
        .left {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding-bottom: 30px;

          .two {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  .template30 {
    padding: 0px 30px 30px 30px;

    .contact {
      padding: 10px;
    }
  }
}

@media screen and (max-width: 1500px) {
  .template30 {
    h3 {
      font-size: 14px;
    }

    .contact {
      padding: 10px 0px;
    }
  }
}

@media screen and (max-width: 1350px) {
  .template30 {
    h1 {
      font-size: 35px;
    }

    li {
      font-size: 13px;
    }

    h3 {
      font-size: 12px;
    }

    p {
      font-size: 12px;
    }

    .description {
      p {
        font-size: 15px;
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .template30 {
    h1 {
      font-size: 30px;
    }

    h3 {
      font-size: 10px;
    }

    h5 {
      font-size: 10px;
    }

    li {
      font-size: 10px;
    }

    p {
      font-size: 10px;
    }

    .contact p span {
      padding-left: 5px;
    }
  }
}