@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.template27 {
  h1 {
    font-size: var(--subHeadingFontSize) !important;
    line-height: var(--t27LineHeight);
    font-family: var(--t27Font);
  }

  h3 {
    font-size: var(--paraFontSize) !important;
    color: var(--t27Color);
    font-weight: bold;
    text-transform: uppercase;
    line-height: var(--t27LineHeight);
    font-family: var(--t27Font);
  }

  h5 {
    font-size: var(--paraFontSize) !important;
    color: black;
    line-height: var(--t27LineHeight);
    font-family: var(--t27Font);
  }

  p {
    font-size: var(--subHeadingFontSizethree) !important;
    color: black;
    margin-bottom: 0;
    line-height: var(--t27LineHeight);
    font-family: var(--t27Font);
  }

  ul {
    list-style-type: disc;
    line-height: var(--t27LineHeight);
    font-family: var(--t27Font);
  }

  li {
    font-size: var(--subHeadingFontSizethree) !important;
    color: black;
    line-height: var(--t27LineHeight);
    font-family: var(--t27Font);
  }

  .experienceSingle {
    margin-bottom: 10px;
  }
}

.template27 {
  .top {
    display: flex;
    height: 230px;
    background: var(--t27Color);

    .left {
      width: 30%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      label {
        width: 250px;
        height: 250px;
        background: black;
        border-radius: 100%;
        position: absolute;
        top: 25%;
        left: 20%;
        z-index: 5;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          // background: red;
          border-radius: 100%;
          // display: none;
        }
      }
    }

    .right {
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  .bottom {
    position: relative;
    display: flex;

    .left {
      width: 40%;
      padding: 120px 30px 30px 30px;

      .content {
        padding-bottom: 30px;

        h3 {
          padding-bottom: 10px;
          border-bottom: 3px solid var(--t27Color);
          width: 100%;
          margin-bottom: 20px;
        }

        p {
          display: flex;
          align-items: center;
          padding-bottom: 10px;
          // word-break: break-all;

          span {
            // word-break: break-all;
            padding-left: 10px;
          }
        }

        ul {
          list-style-type: disc;
          padding-left: 30px;

          li {
            color: black;
            font-size: 15px;
            padding-bottom: 15px;
            // word-break: break-all;

            span {
              font-style: italic;
              font-size: 12px;
              // word-break: break-all;
            }
          }
        }
      }
    }

    .right {
      width: 60%;
      padding: 50px 30px;

      .content {
        padding-bottom: 30px;

        h3 {
          padding-bottom: 10px;
          border-bottom: 3px solid var(--t27Color);
          width: 100%;
          margin-bottom: 20px;
        }

        .italic {
          font-style: italic;
          padding-bottom: 10px;
        }

        p {
          padding-bottom: 10px;
          // word-break: break-all;

          span {
            padding-left: 10px;
          }
        }

        ul {
          list-style-type: disc;
          padding-left: 30px;

          li {
            color: black;
            font-size: 15px;

            // padding-bottom: 5px;
            span {
              font-style: italic;
              font-size: 12px;
            }
          }
        }

        .content-top {
          display: flex;
          padding-bottom: 20px;

          .left-one {
            width: 30%;
            width: 30%;
          }

          .right-one {
            width: 70%;
          }
        }
      }
    }
  }
}

// Media Queries
@media screen and (max-width: 1700px) {
  .template27 p {
    font-size: 12px;
  }
}

@media screen and (max-width: 1500px) {
  .template27 p {
    font-size: 11px;
  }
}

@media screen and (max-width: 1300px) {
  .template27 {
    h5 {
      font-size: 12px;
    }

    p {
      font-size: 10px;
    }

    h3 {
      font-size: 15px;
    }

    .top {
      .left {
        label {
          width: 200px;
          height: 200px;
          top: 60%;
        }
      }
    }

    .bottom {
      .left {
        padding: 120px 0px 30px 20px;

        .content {
          ul {
            li {
              font-size: 11px;
            }
          }
        }
      }

      .right {
        .content {
          ul {
            li {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}