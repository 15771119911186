.Share {
  padding: 14px 0px 0 0px;
  img {
    margin-right: 7px;
  }
  .inside-Share {
    width: 100%;
    margin-top: 32px;
    padding: 16px 42px 48px 27px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);
    .score-on {
      p {
        color: black;
      }
      .share-sec {
        // margin-left: 26px;
        margin-left: 10px;
      }
      .share-btn {
        width: 99px;
        height: 28px;
        border-radius: 4px;
        margin-right: 10px;
        .some-btn {
          color: #fff;
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          background-color: transparent;
        }
      }
    }
    .link {
      p {
        color: black;
      }
      .copy {
        .share-label {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          background-color: #fff;
          padding-right: 20px;
          border-radius: 4px;
          gap: 30px;
        }
        input {
          position: relative;
          background-color: transparent;
          box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);
          border: none;
          width: 80%;
        }
        button {
          padding: 4px;
        }
      }
    }
    .score {
      p {
        color: black;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .Share .inside-Share .score-on .share-sec {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
}
