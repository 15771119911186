.ProofReading {
  padding: 25px 0 0 0px;

  .inside-ProofReading {
    width: 100%;
    margin-top: 29px;
    padding: 20px 0px 21px 33px;
    border-radius: 8px;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);

    .two {
      align-items: center;

      img {
        margin-right: 33px;
      }

      .english {
        background: var(--1, linear-gradient(90deg, #379eff 0%, #4e4bff 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .inside-ProofReading-new {
    width: 100%;
    margin-top: 29px;
    // padding: 20px 0px 21px 0px;
    border-radius: 8px;

    // background: var(
    //   --glass,
    //   linear-gradient(
    //     180deg,
    //     rgba(255, 255, 255, 0.08) 0%,
    //     rgba(255, 255, 255, 0.08) 100%
    //   )
    // );
    .two {
      img {
        margin-right: 8px;
      }

      .english {
        background: var(--1, linear-gradient(90deg, #379eff 0%, #4e4bff 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}


.inside-reviewRequest {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;

  .adminReviewContainer {
    display: flex;
    justify-content: space-between;
    // border: 1px solid black;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);
    padding: 10px;
    align-items: center;

    p {
      font-size: 18px;
    }

    .reviewBtnContainer {
      display: flex;
      gap: 5px;

      button {
        padding: 4px 8px;
        border-radius: 5px;
        background-color: #CB6CE6;
        font-size: 14px;

        &:hover {
          background-color: #d2a3df;
        }

      }
    }
  }
}

.reviewTextModalContainer {
  padding: 20px;
  list-style-type: disc;

  ul {
    list-style-type: disc;
  }

  .reviewTextModal {
    border: 1px solid black;
    padding: 20px 40px;
  }
}