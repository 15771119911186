.dashboard {
  padding: 0px 60px 0px 0px;
  background: #fff;

  .left-new {
    background-color: var(--purple-dark);
    // width: 247px;
    width: 14%;
    height: 100%;
    min-height: 100vh;
    padding: 80px 20px 0 20px;
    border-right: 1px solid #ffffff;
    position: sticky;
    top: 0px;
  }

  .center {
    // width: 795px;
    width: 43%;
    // background: green;
    padding: 20px 20px 80px 34px;
  }

  .right-new {
    // width: 874px;
    width: 58%;
    // background: grey;
    padding: 43px 10px 30px 10px;
    position: sticky;
    top: 0px;

    .right-btn {
      button {
        padding: 0 12px;
        height: 32px;
        margin-left: 17px;
        border-radius: 8px;
        background: transparent;
        border: 1px solid #4e4bff;
        font-size: 20px;
        font-weight: 400;
      }
    }

    .button-sec {
      display: flex;
      align-items: center;
      column-gap: 20px;
      margin-top: 20px;

      button {
        cursor: pointer;
      }

      .ist,
      .second,
      .third {
        padding: 5px 10px;
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background-color: rgb(203, 108, 230);
        border-radius: 10px;
        border: none;
      }

      .forth,
      .fifth {
        background: transparent;
      }
    }

    .resume-preview {
      width: 100%;
      // height: 883px;
      height: fit-content;
      background-color: #ffffff;
      overflow: hidden;
      margin-top: 30px;
      box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);

      &::-webkit-scrollbar {
        display: none;
      }

      .resume-preview-content {
        width: 100%;
        // height: 1100px;
        // border: 1px solid black;
      }
    }
  }

  .threebtnwrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding-top: 15px;

    button {
      width: 33.33%;
    }
  }
}

.side-menu-btn {
  display: none;
}

@media screen and (max-width: 1600px) {
  .dashboard {
    .right-new {
      .resume-preview {
        // transform: scale(0.8);
        // margin-top: -100px;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .dashboard {
    padding: 16px 50px 30px 50px;
    justify-content: space-between;
    position: relative;
    gap: 30px;

    .left-new {
      position: absolute;
      left: 0;
      z-index: 3;
      top: 0;
      width: 250px !important;
    }

    .center {
      width: 45%;
      padding: 0 00px 80px 0px;
    }

    .right-new {
      width: 55%;
      padding: 43px 0px 30px 0px;

      .resume-preview {
        // transform: scale(0.8);
        // margin-top: -130px;
      }
    }

    .side-menu-btn {
      display: block;
      position: absolute;
      right: 20px;
      top: 30px;

      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .dashboard {
    padding: 16px 50px 30px 50px;
    justify-content: space-between;
    position: relative;
    gap: 30px;

    .center {
      h2 {
        font-size: 30px;
      }
    }

    .right-new {
      .resume-preview {
        // transform: scale(1);
        // margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .dashboard {
    padding: 50px 50px 30px 50px;
    justify-content: space-between;
    position: relative;
    gap: 0;
    flex-direction: column;

    .center {
      width: 100% !important;
    }

    .right-new {
      width: 80%;
      margin: 0 auto;

      .resume-preview {
        transform: scale(1);
      }
    }

    .side-menu-btn {
      top: 60px;

      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .dashboard {
    padding: 15px;
    flex-direction: column;

    .center {
      h2 {
        font-size: 25px;
      }
    }

    .right-new {
      padding: 0px;
      width: 90vw;
      margin: 0px;
      overflow: hidden;

      .button-sec {
        column-gap: 5px;
        margin-top: 10px;

        button {
          cursor: pointer;
        }

        .ist,
        .second,
        .third {
          padding: 5px 8px;
          font-size: 11px;
        }

        .forth,
        .fifth {
          background: transparent;
        }
      }

      .resume-preview {
        overflow: hidden;
        // transform: scale(0.5);
        transform-origin: left top;
        width: 100%;
      }

      .right-btn {
        justify-content: flex-start;
        gap: 10px;

        button {
          margin: 0;
        }
      }
    }

    .side-menu-btn {
      top: 35px;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .dashboard {
    .center {
      h2 {
        font-size: 22px;
      }
    }

    .side-menu-btn {
      top: 35px;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
}
