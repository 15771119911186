.selectTemplate-slider {
  background-color: var(--white);
  height: 100%;
  padding: 131px 141px;

  .left {
    width: 480px;
    margin-right: 26px;

    .star {
      margin-top: 139px;
    }
  }

  .imgs {
    img {
      width: 400px;
    }
  }

  .right {
    width: 1100px;
    // background-color: aquamarine;
    padding: 38px 25px;

    .template-slider {
      display: block;
      gap: 20px;

      .slick-slider {
        .slick-prev {
          left: -60px;

          &::before {
            color: var(--brown);
            content: url("../../assets/icons/leftArrow.svg");
          }
        }

        .slick-disabled {
          opacity: 0;
        }

        .slick-next {
          right: 30px;
          transform: rotate(180deg);

          &::before {
            color: var(--brown);
            content: url("../../assets/icons/leftArrow.svg");
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  .selectTemplate-slider {
    padding: 120px 100px;

    .right {
      width: 900px;
      padding: 38px 20px;
    }

    .left .star {
      margin-top: 70px;
    }

    h1 {
      font-size: 30px;
    }
  }
}

@media screen and (max-width: 1500px) {
  .selectTemplate-slider {
    padding: 100px 60px;

    .right {
      width: 740px;
      padding: 10px 10px;
    }

    .left {
      h1 {
        font-size: 20px;
        line-height: 21px;
      }

      .new-para {
        font-size: 14px;
        margin-top: 12px !important;
      }

      .template-btn {
        button {
          min-width: 150px !important;
          min-height: 40px !important;
          font-size: 15px;
        }
      }

      .star {
        margin-top: 50px;

        .iconify--ion {
          width: 38px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .selectTemplate-slider {
    padding: 100px 60px;
    height: fit-content;
    flex-direction: column;

    .right {
      width: 90%;
      padding: 10px 10px;
      margin: 0 auto;
    }

    .left {
      margin: auto;
    }
  }
}

@media screen and (max-width: 992px) {
  .selectTemplate-slider {
    .right {
      img {
        max-width: 90% !important;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .selectTemplate-slider {
    padding: 70px 23px;

    .left {
      max-width: 100%;
    }

    .right {
      width: 100%;

      img {
        max-width: 90% !important;
      }

      .template-slider .slick-slider .slick-prev {
        left: -4px;
        z-index: 2;
      }
    }
  }
}

// @media screen and (max-width:570px) {
//   .selectTemplate-slider{
//     >div{
//       display: flex;
//       justify-content: ;
//     }
//   }
// }