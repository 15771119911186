.bahr {
  .strength {
    .hobbies {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 40px 30px;
      .hobbies-box {
        label {
          position: relative;
          img {
            position: relative;
            top: 6.5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .bahr {
    .strength {
      .hobbies {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
        .hobbies-box {
          label {
            position: relative;
            img {
              position: relative;
              top: 6.5px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .bahr {
    .strength {
      .hobbies {
        display: grid;
        display: flex;
        flex-direction: column;
        .input-box {
          button {
            min-height: 60px !important;
          }
        }
      }
    }
  }
}
