// font-family: var(--t23Font);
// font-size: var(--subHeadingFontSize);
// font-size: var(--paraFontSize);
// line-height: var(--t23LineHeight);
// color: var(--t23Color);

.template23-sec {
  padding: 60px;
  color: black;

  font-family: var(--t23Font) !important;

  p {
    font-family: var(--t23Font) !important;

  }

  ul {
    font-family: var(--t23Font) !important;
  }

  li {
    font-family: var(--t23Font) !important;
  }

  .heading {
    font-size: 30px;
    // font-family: "Poppins";
    font-family: var(--t23Font);
    // line-height: 100%;
    line-height: var(--t23LineHeight);
    color: var(--t23Color);


    // color: #5a98ad;
  }

  .sub-heading {
    // font-size: 22px;
    font-size: var(--subHeadingFontSize);
    margin: 0px;
    padding: 10px 0px;
    text-align: center;
    // line-height: 100%;
    line-height: var(--t23LineHeight);
    color: var(--t23Color);


    // border-bottom: 1px solid #5a98ad;
    border-bottom: 1px solid var(--t23Color);
    // border-top: 1px solid #5a98ad;
    border-top: 1px solid var(--t23Color);
  }

  .status {
    font-size: 18px;
    // font-size: var(--subHeadingFontSize);
    // font-family: "Poppins";
    font-family: var(--t23Font);
    // line-height: 100%;
    line-height: var(--t23LineHeight);

    margin-bottom: 0px;
  }

  .para,
  a {
    // font-size: 15px;
    font-size: var(--paraFontSize);
    margin: 0px;
    // line-height: 120%;
    line-height: var(--t23LineHeight);

    color: rgb(64, 64, 64);

    p {
      // font-size: 15px;
      font-size: var(--paraFontSize);
      margin: 0px;
      // line-height: 120%;
      line-height: var(--t23LineHeight);

      color: rgb(64, 64, 64);
    }
  }

  .bold-para {
    font-weight: bold;
  }

  .light-para {
    font-weight: 500;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 5px;
    padding-bottom: 10px;
    // border-bottom: 2px solid #5a98ad;
    border-bottom: 2px solid var(--t23Color);
    border-radius: 1px;

    img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .summary {
    padding: 10px 0px;
  }

  .contact-sec {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding: 10px 0px;

    div {
      display: flex;
      column-gap: 10px;
    }
  }

  .skill-sec {
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    column-gap: 20px;

    .ist {
      width: 100%;

      div {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
      }
    }
  }

  .education-sec,
  .certificate-sec,
  .experience-sec,
  .project-sec,
  .volunteer-sec,
  .strength-sec,
  .award-sec,
  .interest-sec,
  .language-sec,
  .summary,
  .Address,
  .publication-sec,
  .reference-sec {
    padding: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .ist {
      display: flex;
      flex-direction: column;
      row-gap: 2px;
    }
  }
}