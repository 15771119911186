.btn-prime {
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}
.btn-prime-2 {
  font-size: 13.5px;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}
.btn-prime-3 {
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
}

.btn-primary {
  background: #ac50c6;
}
.btn-secondary {
  background: var(--1, linear-gradient(90deg, #379eff 0%, #4e4bff 100%));
  background: transparent;
  border: 2px solid #4e4bff;
}
.btn-secondary-1 {
  // border: 2px solid #4e4bff;
  border-radius: 4px;
  background: var(--1, linear-gradient(90deg, #379eff 0%, #4e4bff 101.34%));
  outline: none;
  border: none;
}
