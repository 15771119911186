// font-family: var(--t24Font);
// font-size: var(--subHeadingFontSize);
// font-size: var(--paraFontSize);
// line-height: var(--t24LineHeight);
// color: var(--t24Color);

.template24-sec {
  color: black;
  padding: 50px;

  font-family: var(--t24Font) !important;

  p {
    font-family: var(--t24Font) !important;

  }

  ul {
    font-family: var(--t24Font) !important;
  }

  li {
    font-family: var(--t24Font) !important;
  }

  .sub-heading {
    // font-size: 22px;
    font-size: var(--subHeadingFontSize);
    margin: 0px;
    // line-height: 100%;
    line-height: var(--t24LineHeight);
    color: var(--t24Color);


    position: relative;

    &::before {
      content: "";
      position: absolute;
      background-color: black;
      width: 35px;
      bottom: -3px;
      border-radius: 50%;
      height: 2px;
    }
  }

  .heading {
    font-size: 30px;
    // font-size: var(--subHeadingFontSize);
    // font-family: "Poppins";
    font-family: var(--t24Font);
    // line-height: 100%;
    line-height: var(--t24LineHeight);
    color: var(--t24Color);


  }

  .status {
    font-size: 20px;
    // font-size: var(--subHeadingFontSize);
    // font-family: "Poppins";
    font-family: var(--t24Font);
    // line-height: 100%;
    line-height: var(--t24LineHeight);

    margin-bottom: 0px;
  }

  .para,
  a {
    // font-size: 15px;
    font-size: var(--paraFontSize);
    margin: 0px;
    // line-height: 120%;
    line-height: var(--t24LineHeight);

    color: rgb(64, 64, 64);

    p {
      // font-size: 15px;
      font-size: var(--paraFontSize);
      margin: 0px;
      // line-height: 120%;
      line-height: var(--t24LineHeight);

      color: rgb(64, 64, 64);
    }
  }

  .bold-para {
    font-weight: bold;
  }

  .dark-para {
    font-weight: 600;
  }

  .light-para {
    font-weight: 500;
  }

  .top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 30px;
    border-bottom: 1px solid black;

    .right {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: flex-end;
      row-gap: 10px;

      .address,
      .contact {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: flex-end;
        row-gap: 2px;

        div {
          display: flex;
          align-items: center;
          column-gap: 10px;
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    column-gap: 50px;
    padding-top: 30px;

    img {
      width: 200px;
      height: 200px;
      object-fit: cover;
    }

    .right {
      width: 30%;
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      .education-sec,
      .certificate-sec {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .ist {
          display: flex;
          flex-direction: column;
          row-gap: 4px;
        }
      }

      .details-sec,
      .award-sec,
      .summary,
      .Address {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        div {
          display: flex;
          flex-direction: column;
          row-gap: 4px;
        }
      }

      .skill-sec,
      .interest-sec {

        div {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 5px;
          margin-top: 5px;
        }
      }
    }

    .left {
      width: 70%;

      display: flex;
      flex-direction: column;
      row-gap: 20px;

      .profile-sec,
      .expereince-sec,
      .project-sec,
      .volunteer-sec,
      .publication-sec,
      .reference-sec {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .ist {
          display: flex;
          flex-direction: column;
          row-gap: 4px;
        }
      }

      .reference-sec {
        border: none;
      }
    }
  }
}