.work {
  padding: 90px 0px 62px 0px;

  .resume-btn {
    margin-top: 98px;
  }

  // .work-content {
  //   display: flex;
  //   justify-content: space-between;
  //   width: 100%;
  //   .left-content {
  //     .video-wrapper {
  //       width: 563.866px;
  //       height: 318px;
  //       video {
  //         width: 100%;
  //         height: 100%;
  //       }
  //     }
  //     .text-wrapper {
  //       width: 563.866px;
  //       height: 318px;
  //       display: flex;
  //       flex-direction: column;
  //       justify-content: center;
  //       p {
  //         color: #fff;
  //         font-family: "Poppins";
  //         font-size: 18px;
  //         font-style: normal;
  //         font-weight: 300;
  //         line-height: normal;
  //       }
  //       h4 {
  //         color: #fff;
  //         font-family: "Poppins";
  //         font-size: 24px;
  //         font-style: normal;
  //         font-weight: 600;
  //         line-height: normal;
  //         position: relative;
  //       }
  //     }
  //   }

  //   .right-content {
  //     position: relative;
  //     &::after {
  //       content: "";
  //       position: absolute;
  //       left: -100px;
  //       top: 0;
  //       width: 2px;
  //       height: 100%;
  //       background-color: #4e4bff;
  //     }
  //     h4 {
  //       position: relative;
  //       &::after {
  //         position: absolute;
  //         content: url("../../assets/icons/diamond.svg");
  //         left: -116px;
  //         z-index: 2;
  //       }
  //     }
  //   }

  //   .left-diamond {
  //     h4 {
  //       position: relative;
  //       &::after {
  //         position: absolute;
  //         content: url("../../assets/icons/diamond.svg");
  //         right: -114px;

  //         z-index: 2;
  //       }
  //     }
  //   }
  // }
  .vertical-timeline {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 18px;
      height: 100%;
      width: 4px;
      background: #4e4bff;
    }

    .vertical-timeline-element--work {
      // .vertical-timeline-element-icon {
      //   box-shadow: unset !important;
      //   background: url("../../assets/icons/diamond.svg") !important;
      //   width: 34px;
      //   height: 34px;
      //   // left: 50%;
      //   // top: 40%;
      //   // margin-left: -17px;
      // }
    }

    .vertical-timeline-element-content-arrow {
      display: none;
    }

    .vertical-timeline-element-content {
      background: unset !important;
      box-shadow: unset !important;

      video {
        width: 100%;
      }

      .vertical-timeline-element-date {
        opacity: 1;
        padding: 5.8em 2em;
      }
    }
  }
}

// @media screen and (max-width: 1760px) {
//   .work {
//     padding: 90px 190px 62px 190px;

//     .work-content {
//       .left-content {
//         .video-wrapper {
//           width: 563.866px;
//           height: 318px;
//         }
//         .text-wrapper {
//           width: 563.866px;
//           height: 318px;
//           p {
//             font-size: 18px;
//           }
//           h4 {
//             font-size: 24px;
//           }
//         }
//       }

//       .right-content {
//         position: relative;
//         &::after {
//           left: -100px;
//           top: 0;
//           width: 2px;
//         }
//         h4 {
//           &::after {
//             left: -116px;
//           }
//         }
//       }

//       .left-diamond {
//         h4 {
//           position: relative;
//           &::after {
//             right: -154px;
//           }
//         }
//       }
//     }
//   }
// }

// @media screen and (max-width: 1680px) {
//   .work {
//     padding: 90px 190px 62px 190px;

//     .work-content {
//       .left-content {
//         .video-wrapper {
//           width: 563.866px;
//           height: 318px;
//         }
//         .text-wrapper {
//           width: 563.866px;
//           height: 318px;
//           p {
//             font-size: 18px;
//           }
//           h4 {
//             font-size: 24px;
//           }
//         }
//       }

//       .right-content {
//         position: relative;
//         &::after {
//           left: -100px;
//           top: 0;
//           width: 2px;
//         }
//         h4 {
//           &::after {
//             left: -116px;
//           }
//         }
//       }

//       .left-diamond {
//         h4 {
//           position: relative;
//           &::after {
//             right: -73px;
//           }
//         }
//       }
//     }
//   }
// }

// @media screen and (max-width: 1600px) {
//   .work {
//     padding: 90px 90px 62px 90px;

//     .work-content {
//       .left-diamond {
//         h4 {
//           position: relative;
//           &::after {
//             right: -195px;
//           }
//         }
//       }
//     }
//   }
// }

// @media screen and (max-width: 1566px) {
//   .work {
//     padding: 90px 90px 62px 90px;
//     .work-content {
//       .left-diamond {
//         h4 {
//           position: relative;
//           &::after {
//             right: -178px;
//           }
//         }
//       }
//     }
//   }
// }
// @media screen and (max-width: 1480px) {
//   .work {
//     padding: 90px 90px 62px 90px;

//     .work-content {
//       .left-diamond {
//         h4 {
//           position: relative;
//           &::after {
//             right: -90px;
//           }
//         }
//       }
//     }
//   }
// }

// @media screen and (max-width: 1400px) {
//   .work {
//     padding: 90px 90px 62px 90px;

//     .work-content {
//       .left-content {
//         .video-wrapper {
//           width: 430px;
//           height: 290px;
//         }
//         .text-wrapper {
//           width: 430px;
//           height: 290px;

//           p {
//             font-size: 13px;
//           }
//           h4 {
//             font-size: 21px;
//           }
//         }
//       }

//       .left-diamond {
//         h4 {
//           position: relative;
//           &::after {
//             right: -280px;
//           }
//         }
//       }
//     }
//   }
// }

// @media screen and (max-width: 1366px) {
//   .work {
//     padding: 90px 90px 62px 90px;

//     .work-content {
//       .left-content {
//         .video-wrapper {
//           width: 430px;
//           height: 290px;
//         }
//         .text-wrapper {
//           width: 430px;
//           height: 290px;

//           p {
//             font-size: 13px;
//           }
//           h4 {
//             font-size: 21px;
//           }
//         }
//       }

//       .left-diamond {
//         h4 {
//           position: relative;
//           &::after {
//             right: -227px;
//           }
//         }
//       }
//     }
//   }
// }

// @media screen and (max-width: 1280px) {
//   .work {
//     padding: 90px 90px 62px 90px;

//     .work-content {
//       .left-content {
//         .video-wrapper {
//           width: 430px;
//           height: 290px;
//         }
//         .text-wrapper {
//           width: 430px;
//           height: 290px;

//           p {
//             font-size: 13px;
//           }
//           h4 {
//             font-size: 21px;
//           }
//         }
//       }

//       .left-diamond {
//         h4 {
//           position: relative;
//           &::after {
//             right: -143px;
//           }
//         }
//       }
//     }
//   }
// }

// @media screen and (max-width: 1152px) {
//   .work {
//     padding: 90px 40px 62px 40px;

//     .work-content {
//       .left-content {
//         .video-wrapper {
//           width: 430px;
//           height: 290px;
//         }
//         .text-wrapper {
//           width: 430px;
//           height: 290px;

//           p {
//             font-size: 13px;
//           }
//           h4 {
//             font-size: 21px;
//           }
//         }
//       }

//       .left-diamond {
//         h4 {
//           position: relative;
//           &::after {
//             right: -113px;
//           }
//         }
//       }
//     }
//   }
// }

// @media screen and (max-width: 1024px) {
//   .work {
//     padding: 90px 40px 62px 40px;

//     .work-content {
//       .left-content {
//         .video-wrapper {
//           width: 430px;
//           height: 290px;
//         }
//         .text-wrapper {
//           width: 430px;
//           height: 290px;

//           p {
//             font-size: 13px;
//           }
//           h4 {
//             font-size: 21px;
//           }
//         }
//       }

//       .left-diamond {
//         h4 {
//           position: relative;
//           &::after {
//             display: none;
//           }
//         }
//       }

//       .right-content {
//         &::after {
//           display: none;
//         }
//         h4::after {
//           display: none;
//         }
//       }
//     }
//   }
// }

// @media screen and (max-width: 992px) {
//   .work {
//     padding: 90px 23px 62px 23px;

//     .work-content {
//       flex-direction: column;
//       align-items: center;
//       .left-content {
//         .video-wrapper {
//           width: 430px;
//           height: 290px;
//         }
//         .text-wrapper {
//           width: 430px;
//           height: 290px;

//           p {
//             font-size: 13px;
//           }
//           h4 {
//             font-size: 21px;
//           }
//         }
//       }

//       .left-diamond {
//         h4 {
//           position: relative;
//           &::after {
//             display: none;
//           }
//         }
//       }

//       .right-content {
//         &::after {
//           display: none;
//         }
//         h4::after {
//           display: none;
//         }
//       }
//     }
//   }
// }

// @media screen and (max-width: 700px) {
//   .work {
//     padding: 90px 23px 62px 23px;

//     .work-content {
//       flex-direction: column;
//       align-items: center;
//       .left-content {
//         .video-wrapper {
//           width: 100%;
//           height: 290px;
//         }
//         .text-wrapper {
//           width: 100%;
//           max-height: 90px !important;

//           p {
//             font-size: 13px;
//           }
//           h4 {
//             font-size: 21px;
//           }
//         }
//       }

//       .left-diamond {
//         h4 {
//           position: relative;
//           &::after {
//             display: none;
//           }
//         }
//       }

//       .right-content {
//         &::after {
//           display: none;
//         }
//         h4::after {
//           display: none;
//         }
//       }
//     }
//     h1 {
//       font-size: 28px;
//       &:last-child {
//         margin: 0 !important;
//         margin-left: 5px !important;
//       }
//     }
//   }
// }
// @media screen and (max-width: 600px) {
//   .work {
//     .vertical-timeline {
//       padding: unset !important;
//       .vertical-timeline-element--work {
//         .vertical-timeline-element-content {
//           padding: unset !important;
//         }
//         .vertical-timeline-element-icon {
//           left: 7%;
//           top: 15%;
//         }
//       }
//     }
//     padding: 20px;
//     .heading {
//       h1 {
//         font-size: 28px;
//       }
//     }
//     .vertical-timeline-element-date {
//       padding: 0px !important;
//     }
//   }
// }
.vertical-timeline--two-columns .vertical-timeline-element-icon {
  width: 31px !important;
  height: 32px !important;
  margin-left: 4px !important;
  transform: rotate(45deg);
  box-shadow: none;
  // background: red !important;
  background: var(--1,
      linear-gradient(90deg, #379eff 0%, #4e4bff 101.34%)) !important;
  border-radius: 0;
}

@media screen and (min-width: 1170px) {
  .vertical-timeline--two-columns .vertical-timeline-element-icon {
    width: 31px !important;
    height: 32px !important;
    left: 51.2% !important;
    margin-left: -30px !important;
    transform: rotate(45deg);
    box-shadow: none;
    // background: red !important;
    background: var(--1,
        linear-gradient(90deg, #379eff 0%, #4e4bff 101.34%)) !important;
    border-radius: 0;
  }
}

@media screen and (max-width: 600px) {
  .work {
    h1 {
      font-size: 25px;
    }

    .vertical-timeline .vertical-timeline-element-content .vertical-timeline-element-date {
      opacity: 1;
      padding: unset !important;

      .top {
        span {
          font-size: 12px;

          &:last-child {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.ql-container.ql-snow {
  padding-bottom: 50px;
}