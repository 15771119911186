.template28 {

  // Classes for setting properties of tags according to our requirement
  h1 {
    font-size: var(--subHeadingFontSize);
    line-height: var(--t28LineHeight);
    font-family: var(--t28Font);
  }

  h4 {
    font-size: var(----paraFontSize);
    color: #2f2f2f;
    text-transform: uppercase;
    line-height: var(--t28LineHeight);
    font-family: var(--t28Font);
  }

  p {
    font-size: var(--subHeadingFontSizethree);
    color: black;
    margin-bottom: 0;
    line-height: var(--t28LineHeight);
    font-family: var(--t28Font);
  }

  ul {
    list-style-type: circle;
    line-height: var(--t28LineHeight);
    font-family: var(--t28Font);
  }

  li {
    font-size: var(--subHeadingFontSizethree);
    color: black;
    line-height: var(--t28LineHeight);
    font-family: var(--t28Font);
  }

  .skillsAll {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    width: 100% !important;

    p {
      padding-bottom: 0 !important;
    }
  }
}

.template28 {
  // border-radius: 8px;
  // border: 1px solid #2f2f2f;

  .top {
    padding: 50px 50px 10px 50px;
    background: var(--t28Color);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // border-radius: 8px;
    border: 1px solid var(--t28Color);
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 100%;

    h4 {
      border-bottom: 1px solid white;
      padding-bottom: 10px;
      width: 100%;
      text-align: center;
      color: white;
    }

    .epa {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-top: 5px;

      p {
        color: white;

        span {
          font-weight: bold;
          font-size: 17px;
        }
      }
    }
  }

  .bottom {
    padding: 30px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 8px;
    // border: 1px solid #d1d1d5;
    width: 100%;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;

    .content {
      width: 100%;
      padding-bottom: 20px;

      h4 {
        font-weight: bold;
        border-bottom: 2px solid #2f2f2f;
        // padding-bottom: 5px;
        margin-bottom: 10px;
      }

      .two {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        padding-bottom: 10px;
        padding-top: 10px;

        .left {
          ul {
            padding-left: 20px;

            li {
              word-break: break-all;

              span {
                font-weight: bold;
              }
            }
          }
        }
      }

      // .reference {
      //   padding-left: 115px;
      // }
      // .left-last {
      //   width: 30%;
      //   p {
      //     display: flex;
      //     align-items: center;
      //     justify-content: space-between;
      //     padding-bottom: 15px;
      //     span {
      //       input {
      //         width: 80px;
      //       }
      //     }
      //   }
      // }
    }

    .content-last {
      width: 100%;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;

      h4 {
        font-weight: bold;
        border-bottom: 2px solid #2f2f2f;
        // padding-bottom: 5px;
        margin-bottom: 10px;
      }

      .reference {
        padding-left: 115px;
      }

      .last-two {
        display: flex;
        padding-top: 10px;

        .left-last {
          p {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 15px;
            gap: 20px;

            span {
              display: flex;
              align-items: center;

              input {
                width: 80px;
              }
            }
          }
        }

        .right-last {
          .right-top {
            padding-bottom: 20px;
            padding-top: 10px;
          }
        }
      }
    }
  }
}

// Media Queries
@media screen and (max-width: 1700px) {
  .template28 .bottom {
    .content {
      .two {
        .right {
          ul {
            padding-left: 0;
          }
        }
      }
    }

    .content-last .last-two .left-last {
      width: 35%;
    }
  }
}

@media screen and (max-width: 1300px) {
  .template28 {
    h1 {
      font-size: 35px;
    }

    h4 {
      font-size: 24px;
    }

    p {
      font-size: 12px;
    }

    li {
      font-size: 12px;
    }

    .top {
      padding: 50px 20px 10px 20px;
    }

    .bottom {
      padding: 30px 20px;

      .content {
        .two {
          gap: 10px;

          .right {
            padding-left: 50px;
          }
        }
      }
    }
  }
}