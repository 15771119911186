@import url("https://fonts.googleapis.com/css2?family=Joti+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.template6-sec {
  color: black;
  font-family: var(--t6Font);
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  p {
    margin: 0;
  }

  font-family: var(--t6Font) !important;

  p {
    font-family: var(--t6Font) !important;

  }

  ul {
    font-family: var(--t6Font) !important;
  }

  li {
    font-family: var(--t6Font) !important;
  }

  .content {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    padding: 0px 60px 60px 60px;
  }

  .back {
    // background-color: rgb(59, 59, 128);
    background-color: var(--t6Color);
  }

  .head {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 15px 0px;

    h1 {
      margin: 0px;
    }
  }

  .main-heading {
    font-size: 35px;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
  }

  .para {
    font-size: var(--paraFontSize);
    margin: 0px;
    // line-height: 140%;
    line-height: var(--t6LineHeight);

    p {
      line-height: var(--t6LineHeight);
    }
  }

  .bold-para {
    font-weight: bold;
  }

  .large-line {
    width: 100%;
    height: 6px;
    margin-bottom: 5px;
  }

  .small-line {
    width: 100%;
    height: 3px;
    margin: 0px 0px 5px 0px;
  }

  .sub-heading {
    font-size: var(--subHeadingFontSize);
    font-weight: bold;
    color: var(--t6Color);
    // line-height: 140%;
    line-height: var(--t6LineHeight);
  }

  ul {
    padding-left: 20px;

    li {
      list-style: disc;
    }
  }

  .duration {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 20px;

    p {
      width: 200px;
      text-align: end;
    }

    ul {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      column-gap: 20px;
    }
  }

  .inner {
    display: flex;

    align-items: center;
    justify-content: space-between;
    column-gap: 20px;

    p {
      &:last-child {
        display: flex;
        width: 200px;
        justify-content: end; // justify-content: end;
      }
    }
  }
}