.navbar {
  padding: 21px 118px 21px 88px;
  position: relative;
  background: #180045;

  .navbar-toggler {
    border: none !important;
    outline: none;
    padding: 0px;
  }

  .navbar-nav {
    display: flex;
    gap: 41px;
    position: relative;
    z-index: 111;

    .nav-item {
      a {
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 0;
        position: relative;

        &::after {
          transition: all 0.3s ease;
          position: absolute;
          content: "";
          left: 0;
          bottom: -10px;
          height: 2px;
          width: 0%;
          background-color: white;
        }

        &:hover::after {
          width: 100%;
          transform: translateX(0%);
        }

        &.active {
          &::after {
            width: 100%;
          }
        }
      }
    }

    .navbar-brand {
      position: relative;
      z-index: 999999;
      cursor: pointer;


    }
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    opacity: 0.3;
    background: rgba(221, 37, 203, 0.3);
    filter: blur(50px);
    height: 64px;
    position: 1;
  }

  .nav-btns {
    display: flex;
    gap: 0 12px;
    position: relative;
    z-index: 3;
  }

  .navbar-content {
    position: relative;
    z-index: 2;
    padding: 0px;

    .mainLogo {

      width: 116px;
      height: 39px;
      object-fit: cover;

    }
  }
}

.navbar {
  .container-fluid {
    .collapse {
      .dropdown {
        .dropdown-menu {
          padding: 30px 14px 17px 22px;
          display: flex;
          width: 234px;
          flex-direction: column;
          background: #2b1554;
          border: 1px solid rgba(255, 255, 255, 0.37);
          right: -76%;

          li {

            // border-bottom: 1px solid rgba(255, 255, 255, 0.37);
            a {
              color: white;

              .khaali {
                border: 1px solid rgba(255, 255, 255, 0.37);
                width: 139px;
              }
            }
          }

          .new-change {
            border-bottom: none !important;

            .nav-btns {
              margin-top: 30px;
              display: flex;
              flex-direction: column;
              gap: 20px;
            }
          }

          .dropdown-item:focus,
          .dropdown-item:hover {
            background: none;
          }
        }

        .btn {
          border: none !important;
          background: transparent;
        }

        .dropdown-toggle {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .navbar {
    padding: 20px 50px;

    .navbar-nav {
      gap: 30px;

      .nav-item {
        a {
          &::after {
            content: "";
            left: 0;
            bottom: -5px;
          }
        }
      }

      .navbar-brand {
        position: relative;
        z-index: 999999;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 990px) {
  .navbar {
    padding: 21px 50px 21px 50px;

    .container-fluid {
      .navbar-toggler {
        position: relative;
        z-index: 99;
        border: 1px solid white;

        .navbar-toggler-icon {
          filter: invert(1);
        }
      }
    }

    .navbar-collapse {
      margin-top: 30px;

      .navbar-nav {
        gap: 10px;

        li {
          text-align: center;
        }
      }

      .dropdown {
        margin-top: 10px;
      }
    }

    .nav-btns {
      text-align: center;
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }

    .dropdown {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 41px;

      .dropdown-menu {
        right: unset;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .navbar .container-fluid .collapse .dropdown .dropdown-menu {
    right: 5% !important;
    top: 120%;
  }

  .navbar {
    padding: 20px;

    .navbar-nav {
      gap: 10px;
    }

    .dropdown {
      margin-top: 0px;
    }
  }
}