.template12-sec {
  color: rgb(64, 64, 64);
  // color: var(--t12Color);

  background-color: white;
  // font-family: Arial;
  font-family: var(--t12Font);

  // box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.1);

  font-family: var(--t12Font) !important;

  p {
    font-family: var(--t12Font) !important;

  }

  ul {
    font-family: var(--t12Font) !important;
  }

  li {
    font-family: var(--t12Font) !important;
  }

  .heading {
    font-size: 30px;
    // font-size: var(--subHeadingFontSize);

    // line-height: 120%;
    line-height: var(--t12LineHeight);

    margin-bottom: 0px;
    // color: #369188;
    color: var(--t12Color);

  }

  .para,
  a {
    // font-size: 15px;
    font-size: var(--paraFontSize);

    margin: 0px;
    // line-height: 120%;
    line-height: var(--t12LineHeight);

    color: rgb(64, 64, 64);

    p {
      // font-size: 15px;
      font-size: var(--paraFontSize);

      margin: 0px;
      // line-height: 120%;
      line-height: var(--t12LineHeight);

      color: rgb(64, 64, 64);
    }
  }

  p {
    // font-size: 15px;
    font-size: var(--paraFontSize);

    margin: 0px;
    // line-height: 120%;
    line-height: var(--t12LineHeight);

    color: rgb(64, 64, 64);
  }

  .bold-para {
    font-weight: bold;
  }

  .light-para {
    font-weight: 500;
  }

  .dark-para {
    font-weight: 600;
  }

  .status {
    // font-size: 18px;
    font-size: var(--paraFontSize);

    // line-height: 100%;
    line-height: var(--t12LineHeight);

    // color: #369188;
    color: var(--t12Color);

    margin: 0;
  }

  ul {
    padding-left: 20px;

    li {
      list-style: disc;
    }
  }

  .sub-heading {
    text-align: center;
    // font-size: 20px;
    font-size: var(--paraFontSize);

    // line-height: 120%;
    line-height: var(--t12LineHeight);

    font-weight: bold;
    margin: 0;
  }

  .personal-sec {
    .right {
      display: flex;
      flex-direction: column;
      row-gap: 3px;

      .email {
        display: flex;
        align-items: center;
        column-gap: 8px;
      }
    }
  }

  .skill-sec {
    .right {
      display: flex;
      flex-direction: column;
      row-gap: 5px;

      .hard-skill,
      .soft-skill {
        display: flex;
        flex-direction: column;
        gap: 2px;

        div {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;

          .para {
            // background-color: #369188;
            background-color: var(--t12Color);
            ;
            color: white;
            padding: 5px 10px;
            border-radius: 5px;
          }
        }
      }
    }
  }

  .interest-sec,
  .language-sec {
    .right {
      .ist {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        flex-direction: row !important;

        .para {
          width: fit-content;
          // background-color: #369188;
          background-color: var(--t12Color);

          color: white;
          padding: 5px 10px;
          border-radius: 5px;
        }
      }
    }
  }

  .top,
  .personal-sec,
  .skill-sec,
  .experience-sec,
  .project-sec,
  .volunteer-sec,
  .education-sec,
  .certificate-sec,
  .award-sec,
  .publisher-sec,
  .interest-sec,
  .language-sec,
  .reference-sec {
    display: flex;
    border-bottom: 1.5px solid rgb(116, 97, 97);

    .left {
      padding: 30px 50px;
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1.5px solid rgb(116, 97, 97);
    }

    .right {
      padding: 30px 50px;
      width: 70%;

      .ist {
        display: flex;
        flex-direction: column;
        row-gap: 3px;
      }
    }
  }

  .top {
    .left {
      .profile-img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .reference-sec {
    // border: none;
  }
}