.template13-sec {
  color: black;
  padding: 50px;
  background-color: white;
  // font-family: Arial;
  font-family: var(--t13Font) !important;

  p {
    font-family: var(--t13Font) !important;

  }

  ul {
    font-family: var(--t13Font) !important;
  }

  li {
    font-family: var(--t13Font) !important;
  }

  .top {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    .heading {
      font-size: 30px;
      // font-size: var(--subHeadingFontSize);

      // font-family: "Poppins";
      font-family: var(--t13Font);

      // line-height: 100%;
      line-height: var(--t13LineHeight);

      // color: #369188;
      color: var(--t13Color);

    }

    .status {
      // font-size: 18px;
      font-size: var(--paraFontSize);

      // font-family: "Poppins";
      font-family: var(--t13Font);

      // line-height: 100%;
      line-height: var(--t13LineHeight);

      margin-bottom: 0px;
      font-weight: bold;
    }
  }

  .details {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px 0px 30px 0px;
    border-bottom: 2px solid grey;
  }

  .bottom {
    display: flex;
    column-gap: 40px;
    padding: 30px 0px;

    .left {
      width: 30%;
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      .skill-sec,
      .strength-sec,
      .award-sec,
      .interest-sec,
      .language-sec {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        div {
          display: flex;
          flex-direction: column;
          row-gap: 4px;
        }

        span {
          // color: #369188;
          color: var(--t13Color);

        }

        .sub-heading {
          // font-size: 16px;
          font-size: var(--subHeadingFontSize);

          margin: 0px;
          font-weight: bold;
          // color: #369188;
          color: var(--t13Color);

        }
      }
    }

    .right {
      width: 70%;
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      .profile-sec,
      .expereince-sec,
      .project-sec,
      .volunteer-sec,
      .education-sec,
      .certificate-sec,
      .publication-sec,
      .reference-sec {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        border-bottom: 2px solid grey;
        padding: 0px 0px 20px 0px;

        .sub-heading {
          // font-size: 20px;
          font-size: var(--subHeadingFontSize);

          margin: 0px;
          // line-height: 100%;
          line-height: var(--t13LineHeight);

          font-weight: bold;
        }

        .bold-para {
          // color: #369188;
          color: var(--t13Color);

        }

        .ist {
          display: flex;
          flex-direction: column;
          row-gap: 4px;

          .instituteName {
            color: var(--t13Color);
          }
        }
      }

      .reference-sec {
        border: none;
      }
    }
  }

  .para,
  a {
    // font-size: 15px;
    font-size: var(--paraFontSize);

    margin: 0px;
    // line-height: 120%;
    line-height: var(--t13LineHeight);

    color: rgb(64, 64, 64);

    p {
      // font-size: 15px;
      font-size: var(--paraFontSize);

      margin: 0px;
      // line-height: 120%;
      line-height: var(--t13LineHeight);

      color: rgb(64, 64, 64);
    }
  }

  .bold-para {
    font-weight: bold;
  }

  .light-para {
    font-weight: 500;
  }
}