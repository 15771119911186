// font-family: var(--t25Font);
// font-size: var(--subHeadingFontSize);
// font-size: var(--paraFontSize);
// line-height: var(--t25LineHeight);
// color: var(--t25Color);


.template25-sec {
  color: black;
  // font-family: "Arian";
  font-family: var(--t25Font);

  font-family: var(--t25Font) !important;

  p {
    font-family: var(--t25Font) !important;

  }

  ul {
    font-family: var(--t25Font) !important;
  }

  li {
    font-family: var(--t25Font) !important;
  }

  .heading {
    font-size: 30px;
    // font-size: var(--subHeadingFontSize);

    // font-family: "Poppins";
    font-family: var(--t25Font);

    // line-height: 100%;
    line-height: var(--t25LineHeight);

  }

  .line {
    width: 100%;
    height: 1.2px;
    background-color: black;
  }

  .sub-heading {
    // font-size: 20px;
    font-size: var(--subHeadingFontSize);

    margin: 0px;
    // line-height: 100%;
    line-height: var(--t25LineHeight);

    font-weight: bold;
    // color: rgb(43, 88, 43);
    color: var(--t25Color);
  }

  .status {
    font-size: 18px;
    // font-size: var(--subHeadingFontSize);

    // font-family: "Poppins";
    font-family: var(--t25Font);

    // line-height: 100%;
    line-height: var(--t25LineHeight);

    margin-bottom: 0px;
    color: white;
  }

  .para,
  a {
    // font-size: 15px;
    font-size: var(--paraFontSize);

    margin: 0px;
    // line-height: 120%;
    line-height: var(--t25LineHeight);

    p {
      // font-size: 15px;
      font-size: var(--paraFontSize);

      margin: 0px;
      // line-height: 120%;
      line-height: var(--t25LineHeight);
    }

  }

  .bold-para {
    font-weight: bold;
  }

  .light-para {
    font-weight: 500;
  }

  .header {
    padding: 50px;
    // background-color: rgb(43, 88, 43);
    background-color: var(--t25Color);
    color: white;

    .top {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
      padding-bottom: 20px;
    }

    .bottom {
      padding: 5px 0px;
      border-top: 1px solid white;
      border-bottom: 1px solid white;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    padding: 40px;

    .summary-sec,
    .skill-sec,
    .education-sec,
    .certificate-sec,
    .award-sec,
    .interest-sec,
    .experience-sec,
    .project-sec,
    .volunteer-sec,
    .publication-sec,
    .reference-sec {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .second {
        display: flex;
        align-items: center;

        flex-wrap: wrap;
        gap: 5px;
      }

      .ist {
        display: flex;
        flex-direction: column;

        row-gap: 4px;
      }
    }
  }
}