// font-family: var(--t10Font);
// font-size: var(--subHeadingFontSize);
// font-size: var(--paraFontSize);
// line-height: var(--t10LineHeight);
// color: var(--t10Color);


.template10-sec {
  color: rgb(64, 64, 64);
  font-family: var(--t10Font);
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 30px 50px 50px 50px;
  background-color: white;

  font-family: var(--t10Font) !important;

  p {
    font-family: var(--t10Font) !important;

  }

  ul {
    font-family: var(--t10Font) !important;
  }

  li {
    font-family: var(--t10Font) !important;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 40px;

    .right {
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }

  .role {
    font-size: 18px;
    line-height: var(--t10LineHeight);
    margin: 0;
  }

  .contact-sec,
  .skill-sec,
  .education-sec,
  .experience-sec,
  .volunteer-sec,
  .strength-sec,
  .award-sec,
  .publication-sec,
  .interest-sec,
  .reference-sec,
  .certificate-sec,
  .language-sec,
  .project-sec {
    display: flex;
    justify-content: space-between;
    column-gap: 100px;

    .left {
      width: 20%;
    }

    .right {
      width: 80%;
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .ist {
        display: flex;
        flex-direction: column;
        row-gap: 2px;
      }
    }
  }

  .contact-sec {
    .right {
      row-gap: 0px;
    }
  }

  ul {
    padding-left: 20px;

    li {
      list-style: disc;
      font-size: var(--paraFontSize);
      margin: 0px;
      line-height: var(--t10LineHeight);
      font-family: var(--t10Font);
    }
  }

  .heading {
    font-size: 35px;
    line-height: var(--t10LineHeight);
    margin-bottom: 0px;
  }

  .line {
    background-color: rgb(0, 0, 0);
    width: 100%;
    height: 1px;
  }

  .sub-heading {
    font-size: var(--subHeadingFontSize);
    line-height: var(--t10LineHeight);
    font-weight: bold;
    margin: 0;
  }

  .para {
    font-size: var(--paraFontSize);
    margin: 0px;
    line-height: var(--t10LineHeight);

    p {
      font-size: var(--paraFontSize);
      margin: 0px;
      line-height: var(--t10LineHeight);
    }
  }

  .bold-para {
    font-weight: bold;
  }

  .light-para {
    font-weight: 600;
  }
}