.t3-main {
    background: #DDDDDD;
}

.main-contanier-t3 {
    background: white;
    color: black;
}


.main-contanier-t3 p {
    font-size: 14px;
    margin: 3px 0;
}
.main-contanier-t3 h2 {
    font-size: 19px;
}
.main-contanier-t3 h5 {
    font-size: 14px;
}

.main-contanier-t3 h4 {
    font-size: 17px;
    margin: 3px 0;
}

.main-contanier-t3 .main-left-t3 {
    background: #333333;
    color: white;
    position: relative;
}


.main-contanier-t3 .main-left-t3::before {
    content: "";
    position: absolute;
    left: 18px;
    width: 4px;
    height: 100%;
    background: #686868;
    border-radius: 15px;
}

.main-contanier-t3 .main-left-t3 .t3-empty {
    position: absolute;
    left: 0;
    clip-path: polygon(0 0, 0% 100%, 100% 0);
    background: #F0AB1A;
    height: 350px;
    width: 100%;
    z-index: 0;
}

.left-t3 {
    /* border-left: 3px solid #686868; */
    padding-left: 0.5rem;
    margin-top: 4rem;
}

.main-contanier-t3 .left-t3 .t3-potrait-wrap {
    display: flex;
    justify-content: center;

}

.main-contanier-t3 .left-t3 .t3-potrait {
    border-radius: 50% 50% 50% 0;
    overflow: hidden;
    width: 230px;
    height: 230px;
    border: 10px solid white;
    z-index: 1;
}

.main-contanier-t3 .left-t3 .t3-potrait img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/* .main-contanier-t3 .h2-wrap-reuse::before {
    content: "";
    position: absolute;
    left: -30px;
    top: -3px;
    width: 11px;
    height: 46px;
    border: 2px solid #F2AB01;
    background: #F2AB01;
    border-radius: 15px;
    z-index: 2;
} */


.main-contanier-t3 .h2-wrap-reuse .icon-wrap{
    background: #F2AB01;
}
.main-contanier-t3 .main-left-t3 .h2-wrap-reuse .icon-wrap{
    background: white;
}


.main-contanier-t3 .left-t3 .left-icon-wrap {
    background: white;
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 50%;
    object-fit: cover;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

.main-contanier-t3 .left-t3 .left-det-t3 {
    margin-top: 2rem;
    border: 0;
    border-bottom: 1px solid white;
    border-style: dashed;
    padding-bottom: 25px;
    position: relative;
    z-index: 1;
}

.main-contanier-t3 .left-t3 .left-det-t3:last-child {
    border: unset;
}


.main-contanier-t3 .left-t3 ul {
    list-style-type: none;
    padding-left: unset;
}


.main-contanier-t3 .left-t3 ul>li {
    position: relative;
}

/* .main-contanier-t3 .left-t3 .contact ul>li::before {
    content: "";
    position: absolute;
    left: -30px;
    top: 19px;
    width: 12px;
    height: 12px;
    border: 2px solid #F2AB01;
    background: #F2AB01;
    border-radius: 50%;
} */

.main-contanier-t3 .left-t3 .contact {
    margin-top: 4rem;
}
.main-contanier-t3 .left-t3 .contact .contact-wrap .con-det-wrap {
    display: flex;
    align-items: center;
    margin: 8px 0;
}

/* .main-contanier-t3 .left-t3 .contact .contact-wrap .con-det-wrap .left-icon-wrap {
    width: 35px;
    height: 35px;
} */

/* .main-contanier-t3 .left-t3 .references ul>li::before {
    content: "";
    position: absolute;
    left: -30px;
    top: 7px;
    width: 12px;
    height: 12px;
    border: 2px solid #F2AB01;
    background: #F2AB01;
    border-radius: 50%;
} */

.main-contanier-t3 .left-t3 .references .ref-wrap .ref-det-wrap {
    margin: 1.5rem 0;
}

.main-contanier-t3 .left-t3 .references .ref-wrap .ref-det-wrap h4 {
    margin: 6px 0;
}

.main-contanier-t3 .left-t3 .references .ref-wrap .ref-det-wrap p {
    margin: 4px 0;
}

.main-contanier-t3 .left-t3 .strengths-det{
    height: 200px;
}
.main-contanier-t3 .left-t3 .projects-det{
    height: 200px;
}


/* Right */

.main-contanier-t3 .main-right-t3 {
    padding-top: 5rem;
    padding-bottom: 3rem;
    padding-left: 0;
    padding-right: 0;
}


.main-contanier-t3 .main-right-t3::before {
    content: "";
    position: absolute;
    top: 0;
    left: 40px;
    width: 4px;
    height: 100%;
    background: #333333;
    border-radius: 15px;
    z-index: 1;
}


.main-right-t3 .right-t3 {
    padding-left: 2rem;
}

/* .main-contanier-t3 .right-t3 .h2-wrap-reuse::before {
    
    left: -33px;
    top: -3px;
} */

.main-right-t3 .person-det {
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 1rem;
    background-color: #DDDDDD;
    top: 10rem;
    height: 13rem;

}

.main-right-t3 .person-det .per-wrap {
    padding-left: 2.5rem;
}

.main-right-t3 .person-det .per-wrap::before {
    content: "";
    position: absolute;
    left: 37px;
    top: 41px;
    width: 11px;
    height: 8rem;
    border: 2px solid #F2AB01;
    background: #F2AB01;
    border-radius: 15px;
    z-index: 2;
}

.main-right-t3 .person-det h1 {
    font-size: 30px;
    font-weight: 900;
    text-transform: uppercase;
    /* margin-top: 10rem; */
}

.main-right-t3 .person-det h1 span {
    color: #EFAA1A;
}
.main-right-t3 .right-t3 {
    padding-bottom: 35px;
}
.main-right-t3 .right-t3 > div:not(:last-child){
    border-bottom: 1px solid #333333;
    padding-bottom: 2rem;
}

.main-right-t3 .right-t3 .right-det {
    margin-bottom: 2rem;
}


.main-right-t3 .right-t3 .right-det .con-det-wrap {
    margin-bottom: 3rem;
}

.main-right-t3 .right-t3 .right-det .con-det-wrap p:nth-child(2) {
    font-style: italic;
    font-weight: 500;
}

.main-right-t3 .right-t3 .job-exp ul {
    list-style-type: none;
    padding-left: 0;
}

.main-right-t3 .right-t3 .job-exp ul>li {
    position: relative;
}

/* .main-right-t3 .right-t3 .job-exp ul>li::before {
    content: "";
    position: absolute;
    left: -35px;
    top: 6px;
    width: 15px;
    height: 15px;
    border: 2px solid #F2AB01;
    background: #F2AB01;
    border-radius: 50%;
    z-index: 2;
} */

.main-right-t3 .right-t3 .right-det .con-det-wrap .exp-h4-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin: 1rem 0;
    font-weight: 500;
}

.main-right-t3 .right-t3 .right-det .con-det-wrap .exp-h4-wrap h4 {
    font-size: 14px;
    font-weight: bold;
}

.main-right-t3 .right-t3 .skills-wrap {
    margin-bottom: 4rem;
}

.main-right-t3 .right-t3 .skills-wrap .skills-det-wrap .skill-det {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.main-right-t3 .right-t3 .skills-wrap .skills-det-wrap .skill-det p {
    flex: 1;
    font-weight: 500;
}

.main-right-t3 .right-t3 .skills-wrap .skills-det-wrap .skill-det .progress {
    width: 100%;
    flex: 1;
    height: 10px;
}

.main-right-t3 .right-t3 .skills-wrap .skills-det-wrap .skill-det .progress .progress-bar {
    background-color: #F2AB01;
}

.main-right-t3 .t3-empty-2 {
    position: absolute;
    background: #F0AB1A;
    right: 0;
    z-index: 3;
    bottom: 0;
    width: 150px;
    height: 150px;
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}

.main-right-t3 .right-t3 .language-wrap .language-det{
    padding: 10px 3px;
    display: flex;
    flex-wrap: wrap;
}
.main-right-t3 .right-t3 .language-wrap .language-det > li{
    margin-right: 8px;
}

